/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Device, DeviceAction, DeviceState } from "../../types";
import { sortMyDevices } from "../sortMyDevices";

export const deviceReducer = (state: DeviceState, action: DeviceAction): DeviceState => {
    switch (action.type) {
        case "FETCH":
            return {
                ...state,
                devices: sortMyDevices(action.data as Device[], state.sortState),
                // filtered_devices: action.data as Device[],
                total: action.total as number,
                fetched: true,
            };
        case "EDIT_BATCH":
            return {
                ...state,
                devices: action.data as Device[],
            };
        case "EDIT":
            return {
                ...state,
                devices: action.data as Device[],
            };
        case "DELETE_BATCH":
            return {
                ...state,
                selected_devicesIds: [],
            };
        case "DELETE":
            return {
                ...state,
                selected_devicesIds: state.selected_devicesIds.filter((deviceId) => deviceId !== action.deviceId),
            };
        case "SELECT":
            return {
                ...state,
                // eslint-disable-next-line no-negated-condition
                selected_devicesIds: !state.selected_devicesIds.includes(action.deviceId!)
                    ? state.selected_devicesIds.concat(action.deviceId!)
                    : state.selected_devicesIds.filter((deviceId) => deviceId !== action.deviceId),
            };
        case "SELECT_ALL":
            return {
                ...state,
                selected_devicesIds: action.deviceIds as number[],
            };
        case "SELECT_ALL_FILTERED":
            return {
                ...state,
                selected_devicesIds: state.selected_devicesIds.concat(action.deviceIds as number[]),
            };
        case "SEARCH":
            return {
                ...state,
                isSearching: true,
            };
        case "END_SEARCH":
            return {
                ...state,
                isSearching: false,
            };
        case "UPDATE_LICENSE":
            return {
                ...state,
                devices: action.data as Device[],
            };
        case "SORT_NAME_DESC": {
            const sortedDevices = sortMyDevices(state.devices, "NAME_DESC");

            return {
                ...state,
                devices: sortedDevices,
                sortState: "NAME_DESC",
            };
        }

        case "SORT_NAME_ASC": {
            const sortedDevices = sortMyDevices(state.devices, "NAME_ASCE");

            return {
                ...state,
                devices: sortedDevices,
                sortState: "NAME_ASCE",
            };
        }
        case "SORT_LISC_DEACTIVE": {
            const sortedDevices = sortMyDevices(state.devices, "LISC_DEACTIVE");

            return {
                ...state,
                devices: sortedDevices,
                sortState: "LISC_DEACTIVE",
            };
        }

        case "SORT_LISC_ACTIVE": {
            const sortedDevices = sortMyDevices(state.devices, "LISC_ACTIVE");

            return {
                ...state,
                devices: sortedDevices,
                sortState: "LISC_ACTIVE",
            };
        }

        case "PRODUCT_CHANGED": {
            return {
                ...state,
                isSearching: false,
                selected_devicesIds: [],
            };
        }
        default:
            return { ...state };
    }
};
