import { Snackbar, Alert, AlertColor } from "@mui/material";
import React from "react";

interface CustomSnackBarProps {
    message?: string;
    open: boolean;
    handleClose: () => void;
    type: AlertColor;
}

export const CustomSnackBar: React.FC<CustomSnackBarProps> = ({ handleClose, open, message: errorMessage, type }) => (
    <>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => handleClose()}
            sx={{ width: "100%", right: 0, left: 0 }}
        >
            <Alert variant="filled" onClose={() => handleClose()} severity={type}>
                {errorMessage}
            </Alert>
        </Snackbar>
    </>
);
