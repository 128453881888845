import { AxiosResponse } from "axios";
import axiosInstance from "@lib/axios";
import { setEmail, setToken } from "@lib/utils";
import { AuthInputType, AuthResponseType } from "@types";

export const register = async (input: AuthInputType): Promise<AuthResponseType> => {
    const { data }: AxiosResponse<AuthResponseType> = await axiosInstance.post("/auth/user/register", input);

    setToken("auth_token", data.auth_token);
    setToken("refresh_token", data.refresh_token);
    setEmail(input.email);

    return data;
};
