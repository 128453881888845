import React from "react";
import { DeviceTableItem, DeviceTableSkeleton, NoDevicesAvailable } from ".";
import { Device, DeviceState } from "../types";

interface DeviceTableBodyProps {
    isLoading: boolean;
    state: DeviceState;
    onDeleteClicked: (deviceData: Device) => void;
    handleCheckboxChange: (id: number) => void;
    onEditClicked: (deviceData: Device) => void;
    isSmallScreen: boolean;
}

export const DeviceTableBody: React.FC<DeviceTableBodyProps> = React.memo(
    ({ isLoading, state, handleCheckboxChange, onDeleteClicked, onEditClicked, isSmallScreen }) => {
        if (isLoading) {
            return (
                <>
                    {Array.from(Array(10).keys()).map((k) => (
                        <DeviceTableSkeleton isSmallScreen={isSmallScreen} key={k} />
                    ))}
                </>
            );
        }

        if (!isLoading && state.devices.length === 0) {
            return <NoDevicesAvailable isSmallScreen={isSmallScreen} />;
        }

        // if (state.devices.length && !state.devices.length) {
        //     return <NoDevicesAvailable isFiltered isSmallScreen={isSmallScreen} />;
        // }

        return (
            <>
                {state.devices.map((device) => (
                    <DeviceTableItem
                        isSmallScreen={isSmallScreen}
                        key={device.id}
                        device={device}
                        isSelected={state.selected_devicesIds.includes(device.id)}
                        handleCheckboxChange={handleCheckboxChange}
                        handleIndividualDelete={() => onDeleteClicked(device)}
                        handleIndividualEdit={() => onEditClicked(device)}
                    />
                ))}
                {/* {emptyRows > 0 && (
                    <TableRow
                        style={{
                            height: 33 * emptyRows,
                        }}
                    >
                        <TableCell colSpan={6} />
                    </TableRow>
                )} */}
            </>
        );
    }
);
