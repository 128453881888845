import { Button, Container, Typography } from "@mui/material";
import React, { ErrorInfo, useState } from "react";
import { MainLayout } from "@components/Layout";
import { ProfileLayoutStyle } from "@features/profile";
import { ConfirmationModal } from "@components/Shared";
import { copyToClipboard } from "@lib/utils";

interface ErrorPageProps {
    error?: Error;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
    const [open, setIsOpen] = useState(false);
    const reason = error?.toString() ?? "Internal server error";

    return (
        <>
            <MainLayout>
                <Container sx={{ ...ProfileLayoutStyle, flexDirection: "column" }} maxWidth="md">
                    <Typography textAlign="center" variant="h5" component="h5" sx={{ my: 1 }}>
                        Congratulations!!! You found an easter egg... hehe...
                    </Typography>
                    <Typography textAlign="center" color={"gray"} sx={{ my: 0.5 }}>
                        Sorry but looks like there was some internal error, please let our devs know about the details
                    </Typography>
                    <Button
                        onClick={() => {
                            setIsOpen(true);
                        }}
                    >
                        Show error details
                    </Button>
                </Container>
            </MainLayout>
            <ConfirmationModal
                title="An error occured"
                description={reason}
                open={open}
                confirmButtonName={"Copy"}
                confirmButton={() => {
                    copyToClipboard(reason);
                }}
                handleCloseModal={() => setIsOpen(false)}
            />
        </>
    );
};
