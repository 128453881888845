import { AxiosResponse } from "axios";
import axiosInstance from "@lib/axios";
import { DevicesListResponse } from "../types";

export const getDevices = async (
    page: number,
    size: number,
    query = "",
    product_type = ""
): Promise<DevicesListResponse> => {
    const { data }: AxiosResponse<DevicesListResponse> = await axiosInstance.get(
        `/user/devices?page=${page}&size=${size}&query=${query}&product_type=${product_type}`
    );

    return data;
};
