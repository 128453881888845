import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Typography } from "@mui/material";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { FormButton, FormInputField, PasswordFormInputField } from "@components/Form";
import { useApiError, useProducts } from "@lib/provider";
import { AuthInputType, login } from "..";
import { mapError, validateEmail, validatePassword } from "../utils";
import AuthFormBody from "./AuthFormLayout/AuthFormBody";
import AuthFormContainer from "./AuthFormLayout/AuthFormContainer";
import AuthFormGrid from "./AuthFormLayout/AuthFormGrid";
import AuthFormHeader from "./AuthFormLayout/AuthFormHeader";

interface LoginFormProps {}
export const LoginForm: React.FC<LoginFormProps> = React.memo(() => {
    const {
        register,
        formState: { errors: formError },
        handleSubmit,
        setError,
    } = useForm<AuthInputType>();
    const { addError } = useApiError();
    const router = useRouter();
    const { mutateAsync, isLoading } = useMutation(login);
    const { refetch } = useProducts();
    const onSubmit: SubmitHandler<AuthInputType> = async ({ email, password }) => {
        try {
            await mutateAsync({ email, password });
            await refetch();
            if (typeof router.query.next === "string") {
                router.push(router.query.next);
            } else {
                router.push("/devices");
            }
        } catch (error) {
            const errorMessage = (error as any).response?.data.message;

            if (!errorMessage) return;
            const isApi = mapError(errorMessage, setError);

            if (isApi) {
                addError({ error });
            }
        }
    };

    return (
        <>
            <AuthFormContainer>
                <AuthFormHeader formTitle="Login">
                    <LockOutlinedIcon />
                </AuthFormHeader>
                <AuthFormBody handleSubmit={handleSubmit(onSubmit)}>
                    <FormInputField
                        label="Email"
                        required
                        register={register("email", {
                            required: "Please enter your email",
                            validate: validateEmail,
                        })}
                        error={Boolean(formError.email?.message)}
                        helperText={formError.email?.message}
                    />
                    <PasswordFormInputField
                        required
                        register={register("password", {
                            required: "Please enter your password",
                            validate: validatePassword,
                        })}
                        label="Password"
                        error={Boolean(formError.password?.message)}
                        helperText={formError.password?.message}
                    />
                    <FormButton isLoading={isLoading} text="Login" />
                    <AuthFormGrid linkto="/register" text={"Don't have an account?"} />
                </AuthFormBody>
                {typeof router.query.next === "string" && (
                    <Typography color="error" sx={{ fontStyle: "italic", fontSize: "small", mt: "16px" }}>
                        Your session was invalid, please login again to continue
                    </Typography>
                )}
            </AuthFormContainer>
        </>
    );
});
