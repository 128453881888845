import { SortType } from "..";

export const getSortDirection = (sortState: SortType): "asc" | "desc" | undefined => {
    switch (sortState) {
        case "NAME_ASCE":
        case "LISC_ACTIVE":
            return "desc";
        case "NAME_DESC":
        case "LISC_DEACTIVE":
            return "asc";
        default:
            return undefined;
    }
};
