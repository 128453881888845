import { useApiError, useColorMode, useProducts } from "@lib/provider";
// import { getProductFromId } from "@lib/utils/products";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { useMutation } from "react-query";
import { UserDataResponse, customerPortal } from "..";

interface InstancesCardProps {
    userInfo: UserDataResponse;
    refreshData: () => void;
    isLoadingProfile: boolean;
}

export const InstancesCard: React.FC<InstancesCardProps> = ({ userInfo, refreshData, isLoadingProfile }) => {
    const { push } = useRouter();
    const { mutateAsync, isLoading } = useMutation(customerPortal);
    const { addError } = useApiError();
    const handleCustomerPortalClicked = async () => {
        try {
            const { message } = await mutateAsync();

            window.location.href = message;
        } catch (error) {
            addError({ error });
        }
    };
    const { getProductFromId } = useProducts();
    const { product } = useColorMode();

    return (
        <>
            <Card sx={{ width: "90%", position: "relative" }}>
                <CardHeader title="Licenses" />
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table aria-label="license table" size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell align="right">Active</TableCell>
                                    <TableCell align="right">Used</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userInfo.product_info
                                    .filter((i) => i.id.includes(product))
                                    .map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {getProductFromId(row.id).name}
                                            </TableCell>
                                            <TableCell align="right">{row.active_licenses}</TableCell>
                                            <TableCell align="right">{row.used_licenses}</TableCell>
                                            <TableCell align="right">{row.total_licenses}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <LoadingButton
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px",
                        }}
                        onClick={() => {
                            refreshData();
                        }}
                        loading={isLoadingProfile}
                    >
                        Refresh Data
                    </LoadingButton>
                </CardContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        m: "8px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {userInfo.has_customer && (
                        <LoadingButton
                            sx={{
                                m: "4px",
                            }}
                            onClick={async () => {
                                await handleCustomerPortalClicked();
                            }}
                            fullWidth
                            variant="outlined"
                            loading={isLoading}
                        >
                            Customer Portal
                        </LoadingButton>
                    )}
                    <Button
                        onClick={() => {
                            push("/payment");
                        }}
                        sx={{
                            m: "4px",
                        }}
                        fullWidth
                        variant="contained"
                    >
                        Get More
                    </Button>
                </Box>
            </Card>
        </>
    );
};
