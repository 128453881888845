import React from "react";
import { Avatar, Typography, Paper } from "@mui/material";
import { AuthFormHeaderAvtar } from "../../style";

interface AuthFormHeaderProps {
    formTitle: string;
}

const AuthFormHeader: React.FC<AuthFormHeaderProps> = ({ formTitle, children }) => (
    <>
        <Avatar sx={AuthFormHeaderAvtar} component={Paper} elevation={2}>
            {children}
        </Avatar>
        <Typography component="h1" variant="h5">
            {formTitle}
        </Typography>
    </>
);

export default AuthFormHeader;
