import React from "react";
import { ConfirmationModal } from "@components/Shared";

interface LogoutModalProps {
    handleLogout: () => void;
    handleClose: () => void;
    isOpen: boolean;
}

export const LogoutModal: React.FC<LogoutModalProps> = ({ handleLogout, handleClose, isOpen }) => (
    <>
        <ConfirmationModal
            open={isOpen}
            title="Do you want to log out?"
            confirmButtonName="Logout"
            confirmButton={() => handleLogout()}
            handleCloseModal={() => handleClose()}
        />
    </>
);
