import { MenuItem } from "@mui/material";
import { Product } from "@types";
import React from "react";
import { ConfirmationModalWithSelectButton } from "./ConfirmationModalWithSelectButton";
import { useProducts } from "@hooks";

interface ProductConformationModalProps {
    open: boolean;
    onConfirmClick: () => void;
    product: Product;
    setProduct: React.Dispatch<React.SetStateAction<Product>>;
    title: string;
    description?: string | React.ReactNode;
    cancleButtonVariant?: "text" | "outlined" | "contained";
    cancleButtonName?: string;
    clickAway?: boolean;
    disableButton?: boolean;
    defaulltValue?: Product;
    handleCloseModal: () => void;
}

export const ProductConformationModal: React.FC<ProductConformationModalProps> = ({
    onConfirmClick,
    product,
    setProduct,
    ...props
}) => {
    const { getProductFromId, getFilteredProducts } = useProducts();

    return (
        <ConfirmationModalWithSelectButton
            onClick={() => onConfirmClick()}
            onChange={(v) => setProduct(getProductFromId(v as string))}
            value={product.id}
            {...props}
        >
            {getFilteredProducts().map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                    Activate {name}
                </MenuItem>
            ))}
        </ConfirmationModalWithSelectButton>
    );
};
