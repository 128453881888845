import { StyleType } from "@themes";

export const headerStyle: StyleType = {
    justifyContent: { md: "space-around", xs: "space-between" },
    alignItems: "center",
    flexDirection: "row",
};

export const footerStyle: StyleType = {
    py: 3,
    px: 2,
    mt: "auto",
    textAlign: "center",
};

export const mainLayoutStyle: StyleType = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
};
