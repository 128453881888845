import { ColorModeType } from "@lib/provider";
import { PaletteOptions } from "@mui/material/styles";
// Create a theme instance.

const lightTheme: PaletteOptions = {
    mode: "light",
    text: {
        primary: "#212121",
        secondary: "#757575",
    },
    error: {
        main: "#d32f2f",
    },
    divider: "#BDBDBD",
};

const darkTheme: PaletteOptions = {
    mode: "dark",
    text: {
        primary: "#fff",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "#424242",
    },
    divider: "#424242",
    error: {
        main: "#ef5350",
    },
};

const aegisPallet = {
    light: {
        primary: {
            main: "#d9027d",
            dark: "#b9016b",
            light: "#f2549a",
        },
        secondary: {
            main: "#ff7f50",
            light: "#ff7f50",
            dark: "#ff7f50",
        },
    },
    dark: {
        primary: {
            main: "#d9027d",
            dark: "#b9016b",
            light: "#f2549a",
        },
        secondary: {
            main: "#ff7f50",
            light: "#ff7f50",
            dark: "#ff7f50",
        },
    },
};

const atlasPallet = {
    light: {
        primary: {
            main: "#2196F3",
            dark: "#1976D2",
            light: "#BBDEFB",
        },
        secondary: {
            main: "#FF5722",
        },
    },
    dark: {
        primary: {
            main: "#2196F3",
            dark: "#1976D2",
            light: "#BBDEFB",
        },
        secondary: {
            main: "#FF9471",
        },
    },
};

export function getTheme(mode: Pick<ColorModeType, "product" | "color">): PaletteOptions {
    const productPallet = mode.product === "atlas" ? atlasPallet[mode.color] : aegisPallet[mode.color];
    const mainPallete = mode.color === "light" ? lightTheme : darkTheme;

    return { ...productPallet, ...mainPallete };
}
