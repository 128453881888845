import React from "react";
import { CustomSnackBar } from "@components/Shared";
import { useApiError } from "..";

interface ApiErrorNotificationProps {}

export const ApiErrorNotification: React.FC<ApiErrorNotificationProps> = () => {
    const {
        error: { isError, message },
        removeError,
    } = useApiError();
    const handleClose = () => {
        removeError();
    };

    return (
        <>
            <CustomSnackBar open={isError} type="error" message={message} handleClose={handleClose} />
        </>
    );
};
