/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import { BlobState } from "@features/payments";

const generateColorFromString = (str: string): string => {
    const stringRepeated = str.repeat(80);
    let hash = 0;

    for (let i = 0; i < stringRepeated.length; i++) {
        hash = stringRepeated.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";

    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;

        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
};

const generateNumberFromString = (string: string, min: number, reverse = false): number => {
    const nameLen = Array.from(string.split("@")[0])
        .map((char) => char.charCodeAt(0))
        .reduce((prev, curr) => prev + curr);
    const totalLen = Array.from(string)
        .map((char) => char.charCodeAt(0))
        .reduce((prev, curr) => prev + curr);
    let num = 0;

    if (reverse) {
        num = (totalLen / nameLen) * 10;
    } else {
        num = nameLen / totalLen;
    }
    if (num < min) {
        num += min;
    } else if (!reverse) {
        num *= 2;
    }

    return parseFloat(num.toFixed(2));
};

export const blobRandomizer = (email: string): BlobState => {
    const color = generateColorFromString(email);
    const spike = generateNumberFromString(email, 0.4);
    const shine = Math.floor(generateNumberFromString(email, 30, true));

    return { color, spike, shine };
};
