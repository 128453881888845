import env from "@beam-australia/react-env";
import axios from "axios";
import { onRequest, onResponseReject } from "./interceptorMethods";
import { isProd } from "@lib/utils";

const axiosInstance = axios.create({
    baseURL: `http${isProd ? "s" : ""}://${env("ENDPOINT")}/atlas`,
    timeout: 10000,
});

axiosInstance.interceptors.request.use(onRequest, (error) => {
    Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => response, onResponseReject);

export default axiosInstance;
