import { copyToClipboard } from "@lib/utils";
import { Typography, Box, Button } from "@mui/material";
import Image from "next/image";
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
}
const defaultState = {
    hasError: false,
    error: undefined,
};

export class CanvasErrorBoundary extends Component<Props, State> {
    public state: State = defaultState;

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI

        return { hasError: true, error };
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "100%",
                        zIndex: 10000,
                    }}
                >
                    <Typography color="white" zIndex={1000}>
                        Something went wrong while trying to load your blob
                    </Typography>
                    <Image priority src="/static/asset/sad_blob.gif" alt="sad_blob" layout="fill" />
                    <Button
                        onClick={() => {
                            copyToClipboard(this.state?.error?.toString() as string);
                        }}
                        sx={{ mx: 0 }}
                    >
                        Copy Details
                    </Button>
                </Box>
            );
        }

        return this.props.children;
    }
}
