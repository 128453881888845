import { useColorMode } from "@lib/provider";
import { toTitleCase } from "@lib/utils";
import PersonIcon from "@mui/icons-material/Person";
import { Checkbox, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import Link from "next/link";
import React from "react";

interface NoDevicesAvailableProps {
    isFiltered?: boolean;
    isSmallScreen?: boolean;
}

export const NoDevicesAvailable: React.FC<NoDevicesAvailableProps> = ({ isFiltered, isSmallScreen }) => {
    const { product } = useColorMode();

    return (
        <>
            <>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox color="primary" disabled />
                    </TableCell>
                    <TableCell align="left">
                        {isFiltered
                            ? "No devices matched your search"
                            : `You don't seem to have any device(s) using ${toTitleCase(
                                  product
                              )}. Connect them with your credentials to get started.`}
                    </TableCell>
                    {!isSmallScreen && (
                        <TableCell sx={{ fontSize: "16px", color: "dimgrey", px: "8px" }} align="left">
                            None
                        </TableCell>
                    )}
                    <TableCell align="center">
                        <Tooltip title="Profile" sx={{ display: "inline-block" }}>
                            <IconButton>
                                <Link passHref href="/profile">
                                    <PersonIcon />
                                </Link>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </>
        </>
    );
};
