import { CacheProvider, EmotionCache } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { AppProps } from "next/app";
import React, { useReducer } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { CustomSnackBar, GlobalErrorBoundary } from "../components/Shared";
import {
    ApiErrorNotification,
    APIErrorProvider,
    ColorModeProvider,
    Notification,
    NotificationProvider,
    ProductProvider,
} from "../lib/provider";
import { errorSnackbarDispatcher, errorSnackbarReducer } from "../lib/utils";
import "../styles/globals.css";
import { createEmotionCache } from "../themes";
import { MainLayout } from "@components/Layout";

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

const MyApp: React.FC<MyAppProps> = (props) => {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
    const [state, dispatcher] = useReducer(errorSnackbarReducer, { isOpen: false, message: "" });

    if (typeof window !== "undefined") {
        document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);

        window.addEventListener("resize", () => {
            document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
        });
    }

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 60 * 10 * 1000, // 10 mins
                onError: (error) => {
                    errorSnackbarDispatcher({ error, dispatcher, statusCodes: [500] });
                },
            },
            mutations: {
                onError: (error) => {
                    errorSnackbarDispatcher({ error, dispatcher, statusCodes: [500] });
                },
            },
        },
    });

    const handleCloseServerErrorMessage = () => {
        dispatcher({ type: "RESET", message: "" });
    };

    return (
        <CacheProvider value={emotionCache}>
            <ColorModeProvider>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <NotificationProvider>
                    <APIErrorProvider>
                        <QueryClientProvider client={queryClient}>
                            <GlobalErrorBoundary>
                                <ProductProvider>
                                    <MainLayout>
                                        <Component {...pageProps} />
                                    </MainLayout>
                                </ProductProvider>
                            </GlobalErrorBoundary>
                            <CustomSnackBar
                                open={state.isOpen}
                                handleClose={handleCloseServerErrorMessage}
                                message={state.message}
                                type="error"
                            />
                            <ApiErrorNotification />
                            <Notification />
                        </QueryClientProvider>
                    </APIErrorProvider>
                </NotificationProvider>
            </ColorModeProvider>
        </CacheProvider>
    );
};

export default MyApp;
