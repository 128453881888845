import { StyleType } from "@themes";

export const FormModalStyle: StyleType = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: 350,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};
export const FormModalAvtar: StyleType = {
    bgcolor: "secondary.main",
    position: "absolute",
    top: "-20px",
};
