import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useState } from "react";
import { AnimatedCashBlobComponent } from ".";
import { ButtonComponent, TypographyComponent } from "../style";

interface SuccessComponentProps {}

export const SuccessComponent: React.FC<SuccessComponentProps> = () => {
    const [animationEnd, setAnimationEnd] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimationEnd(true);
        }, 4900);

        return () => {
            setAnimationEnd(false);
            clearTimeout(timeout);
        };
    }, []);

    const { push } = useRouter();

    return animationEnd ? (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography sx={{ ...TypographyComponent }} variant="h4">
                    Purchase Successful
                </Typography>
                <Button
                    onClick={() => {
                        push("/devices");
                    }}
                    variant="contained"
                    sx={{ m: 2, ...ButtonComponent }}
                >
                    GO TO DASHBOARD
                </Button>
            </Box>
        </>
    ) : (
        <AnimatedCashBlobComponent />
    );
};
