export enum LicenseStatus {
    INACTIVE,
    PARTIAL,
    ACTIVE,
}
export type Device = {
    id: number;
    device_name: string;
    uses_own_config: boolean;
    license_status: LicenseStatus;
    product_id: string;
};

export type SortType = "NAME_ASCE" | "NAME_DESC" | "LISC_ACTIVE" | "LISC_DEACTIVE" | "NONE";
