import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Button, ButtonGroup, Menu } from "@mui/material";
import React from "react";

export type SelectButtonProps = {
    textPrefix?: string;
    onChange?: (value: unknown) => void;
    onClick?: (value: unknown) => void;
    value?: string;
    children: React.ReactNode;
    classes?: any;
    variant?: "text" | "outlined" | "contained";
};

export const SelectButton = (props: SelectButtonProps) => {
    const {
        textPrefix,
        onChange = () => {},
        onClick = () => {},
        value: initialValue,
        children,
        variant = "text",
    } = props;

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = React.useState(false);

    const valueRef = React.useRef(initialValue);

    valueRef.current = initialValue;

    const handleItemClick = (value: string) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setOpen(false);
        Reflect.defineProperty(e, "target", { writable: true, value: { value } });
        valueRef.current = value;
        onChange(value);
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const value = valueRef.current;

        Reflect.defineProperty(e, "target", { writable: true, value: { value } });
        valueRef.current = value;
        onClick(value);
    };

    const items = React.Children.map(children as React.ReactElement, (child) => {
        if (!child) {
            return null;
        }
        const selected = valueRef.current === child.props.value;
        const valueReadable = child.props.children;
        const item = React.cloneElement(child, {
            "aria-selected": selected ? "true" : undefined,
            onClick: handleItemClick(child.props.value),
            role: "option",
            selected,
            value: undefined, // The value is most likely not a valid HTML attribute.
            "data-value": child.props.value, // Instead, we provide it as a data attribute.
            "data-value-readable": valueReadable,
        });

        return item;
    }).filter((item) => item !== null);

    const displayName = (value: any) => items.find((item) => item!.props["data-value"] === value);

    return (
        <>
            <ButtonGroup
                variant={variant}
                ref={anchorRef}
                sx={{
                    "& .Mui-selected": {
                        backgroundColor: "transparent !important",
                    },
                }}
            >
                <Button sx={{ py: 0 }} size="small" onClick={handleButtonClick}>
                    {textPrefix}
                    {displayName(valueRef.current)}
                </Button>
                <Button sx={{ py: 0 }} size="small" onClick={() => setOpen(true)}>
                    <KeyboardArrowDown />
                </Button>
            </ButtonGroup>
            <Menu
                open={isOpen}
                onClose={() => setOpen(false)}
                // get={null} // needed for anchorOrigin to work
                anchorEl={anchorRef.current}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {items}
            </Menu>
        </>
    );
};

SelectButton.displayName = "SelectButton";

export default SelectButton;
