import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import { TableCell, TableRow } from "@mui/material";
import Link from "next/link";
import React from "react";

interface NoInstancesUsedProps {}

export const NoInstancesUsed: React.FC<NoInstancesUsedProps> = () => (
    <>
        <TableRow>
            <TableCell sx={{ fontSize: "16px" }}>This device has no instances assinged to it</TableCell>
            <TableCell align="right">
                <Link passHref href="/devices">
                    <DeviceUnknownIcon sx={{ mr: 2.5 }} />
                </Link>
            </TableCell>
        </TableRow>
    </>
);
