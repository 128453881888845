import { IndicatorColor } from "@types";

export const handlePathUpdate = (pathValues: string[], pathName: string): { color: IndicatorColor; value: number } => {
    const pathValue = pathValues.indexOf(pathName);

    if (pathValue !== -1) {
        return { color: "secondary", value: pathValue };
    }

    return { color: "primary", value: 0 };
};
