import { StyleType } from "@themes";

export const SettingsTooltipStyle: StyleType = {
    display: "inline-block",
    "& svg": {
        // color: "rgba(255,255,255,0.8)",
        transition: "0.2s",
        transform: "translateX(0) rotate(0)",
    },
    "&:hover, &:focus": {
        bgcolor: "unset",
        "& svg:first-of-type": {
            transform: "translateX(-4px) rotate(-20deg)",
        },
        "& svg:last-of-type": {
            right: 0,
            opacity: 1,
        },
    },
    "&:after": {
        // eslint-disable-next-line prettier/prettier, quotes
        content: '""',
        position: "absolute",
        height: "80%",
        display: "block",
        left: 0,
        width: "1px",
    },
};

export const ConfirmationModalStyle: StyleType = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: { xs: "96%", sm: "80%", md: "50%" },
    maxWidth: 500,
};
