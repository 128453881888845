/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ProductsContext, defaultValue, useColorMode } from "@lib/provider";
import { Product } from "@types";
import { useCallback, useContext } from "react";

export const useProducts = () => {
    const { products, refetch, isLoading } = useContext(ProductsContext);
    const { product: productType } = useColorMode();

    const getFilteredProducts = useCallback(() => {
        return products.filter((p) => p.id.includes(productType));
    }, [productType, products]);

    const getDefaultProduct = useCallback(
        (inactive = false) => {
            const filtered = getFilteredProducts();

            if (filtered.length && !inactive) {
                return filtered[0];
            }

            return defaultValue.products[0];
        },
        [getFilteredProducts]
    );

    const getProductFromId = useCallback(
        (id: string) => {
            return products.find((p) => p.id === id) ?? getDefaultProduct();
        },
        [products, getDefaultProduct]
    );
    const getProductFromAllowedWorkers = useCallback(
        (allowed_workers: number) => {
            return products.find((p) => p.allowed_workers === allowed_workers) ?? getDefaultProduct();
        },
        [products, getDefaultProduct]
    );
    const getShortName = useCallback((product: Product) => {
        const [_, type] = product.name.split(" ");

        return type;
    }, []);

    const getSortedProducts = useCallback(() => {
        return products.sort((a, b) => {
            const productA = a.id.includes(productType);
            const productB = b.id.includes(productType);

            if (productA && !productB) {
                return -1; // a should come before b
            } else if (!productA && productB) {
                return 1; // b should come before a
            } else {
                return 0; // maintain the original order
            }
        });
    }, [productType, products]);

    return {
        products,
        getShortName,
        getProductFromAllowedWorkers,
        getProductFromId,
        refetch,
        isLoadingProducts: isLoading,
        getDefaultProduct,
        getFilteredProducts,
        getSortedProducts,
    };
};
