import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ConfigFormContainerStyle } from "..";

interface FormSkeletonProps {}

export const FormSkeleton: React.FC<FormSkeletonProps> = () => (
    <>
        <Box sx={ConfigFormContainerStyle}>
            {Array.from(Array(4).keys()).map((key) => (
                <Typography key={key} component="div" variant="h2">
                    <Skeleton />
                </Typography>
            ))}
        </Box>
    </>
);
