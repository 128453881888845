import { AxiosResponse } from "axios";
import env from "@beam-australia/react-env";
import axiosInstance from "@lib/axios";
import { CreateCheckoutRequest, MessageResponseType } from "..";

export const createCheckout = async ({ quantity, product_id }: CreateCheckoutRequest): Promise<MessageResponseType> => {
    const { data }: AxiosResponse<MessageResponseType> = await axiosInstance.post("/stripe/createCheckout", {
        quantity,
        product_id,
    });

    return data;
};
