import { FormModal, ModalFormButtons, PasswordFormInputField } from "@components/Form";
import { validatePassword } from "@features/auth";
import { useApiError } from "@lib/provider";
import { removeAuthTokens } from "@lib/utils";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { ChangePasswordFormInput } from "..";
import { editInfo } from "../api";
import { mapError, validateAndConfirmEquals } from "../utils";

interface ChangePasswordModalProps {
    isOpen: boolean;
    handleClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ handleClose, isOpen }) => {
    const router = useRouter();
    const { addError } = useApiError();
    const { mutateAsync, isLoading } = useMutation(editInfo);
    const {
        register,
        formState: { errors: formError },
        handleSubmit,
        setError,
        getValues,
        reset,
    } = useForm<ChangePasswordFormInput>();

    const onSubmit: SubmitHandler<ChangePasswordFormInput> = async ({ new_password, old_password }) => {
        try {
            await mutateAsync({ new_password, old_password });
            removeAuthTokens();
            router.push("/login");
        } catch (error) {
            const errorMessage = (error as any).response?.data.message;

            if (!errorMessage) return;
            const isApi = mapError(errorMessage, setError);

            if (isApi) {
                addError({ error });
            }
        }
    };
    const closeAndResetModal = () => {
        handleClose();
        reset({
            old_password: "",
            new_password: "",
            new_password2: "",
        });
    };

    return (
        <>
            <FormModal
                heading="Change Password"
                open={isOpen}
                handleClose={closeAndResetModal}
                handleSubmit={handleSubmit(onSubmit)}
                icon={<VpnKeyIcon />}
            >
                <PasswordFormInputField
                    register={register("old_password", {
                        required: "Please provide your password",
                        validate: validatePassword,
                    })}
                    label="Old Password"
                    error={Boolean(formError.old_password?.message)}
                    helperText={formError.old_password?.message}
                />
                <PasswordFormInputField
                    register={register("new_password", {
                        required: "Please provide your new password",
                        validate: (value) => validateAndConfirmEquals(value, getValues().old_password, false),
                    })}
                    label="New Password"
                    error={Boolean(formError.new_password?.message)}
                    helperText={formError.new_password?.message}
                />
                <PasswordFormInputField
                    register={register("new_password2", {
                        required: "Please confirm your new password",
                        validate: (value) => validateAndConfirmEquals(value, getValues().new_password, true),
                    })}
                    label="Confirm Password"
                    error={Boolean(formError.new_password2?.message)}
                    helperText={formError.new_password2?.message}
                />
                <ModalFormButtons closeFn={handleClose} text="Submit" isLoading={isLoading} />
            </FormModal>
        </>
    );
};
