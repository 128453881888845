import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { FormButtonProps } from ".";

interface ModalFormButtonProps extends FormButtonProps {
    closeFn: () => void;
}

export const ModalFormButtons: React.FC<ModalFormButtonProps> = ({ text, isLoading, closeFn }) => (
    <>
        <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, borderRadius: "25px" }}
            loading={isLoading}
        >
            {text}
        </LoadingButton>
        <Button onClick={closeFn} fullWidth variant="contained" sx={{ mt: 1, mb: 2, borderRadius: "25px" }}>
            Cancel
        </Button>
    </>
);
