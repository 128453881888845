import { ConfirmationModalStyle } from "@components/Shared";
import { Box, Button, Container, Modal, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { Product } from "@types";
import React from "react";
import { Instance, InstanceState } from "../types";
import { InstanceTableBody } from "./InstanceTableBody";
import { InstanceTableHeader } from "./InstanceTableHeader";

interface InstanceTableDialogProps {
    onDeleteInstanceClicked: (instance: Instance) => void;
    onEditInstanceClicked: (instance: Instance) => void;
    product: Product;
    state: InstanceState;
    open: boolean;
    handleClose(): void;
}

export const InstanceTableDialog: React.FC<InstanceTableDialogProps> = ({
    onDeleteInstanceClicked,
    onEditInstanceClicked,
    product,
    state,
    open,
    handleClose,
}) => {
    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Container maxWidth="sm" sx={ConfirmationModalStyle}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography variant="h5"> Manage License </Typography>
                        <Button sx={{ fontWeight: 500 }} onClick={handleClose} variant="text">
                            Close
                        </Button>
                    </Box>
                    <TableContainer
                        sx={{
                            "&::-webkit-scrollbar": {
                                width: "2px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                visibility: "hidden",
                            },
                            ":hover": {
                                "&::-webkit-scrollbar-thumb": {
                                    visibility: "visible",
                                },
                            },
                            maxHeight: "75vh",
                        }}
                    >
                        <Table size="medium" stickyHeader aria-label="your devices">
                            <InstanceTableHeader />
                            <TableBody>
                                {
                                    <InstanceTableBody
                                        handleDeleteInstance={onDeleteInstanceClicked}
                                        handleEditInstance={onEditInstanceClicked}
                                        instances={state.data}
                                        isLoading={false}
                                        product={product}
                                    />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Modal>
        </>
    );
};
