import { useProducts } from "@hooks";
import { Tooltip, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import { Product } from "@types";

const now = Date.now();
const ONE_DAY = 1000 * 60 * 60 * 24;

const getColor = (daysDiff: number | undefined = undefined) => {
    if (typeof daysDiff === "undefined") return "green";
    if (daysDiff <= 0) return "red";
    if (daysDiff <= 3) return orange;

    return "green";
};
const hasExpired = (num: number) => {
    if (num <= 0) return true;

    return false;
};
const getEndsAtTextInfo = (ends_at?: number) => {
    if (!ends_at) return { toastText: "", daysLeftText: "", color: "green" };
    const date = new Date(ends_at * 1000);
    const daysDiff = Math.round((ends_at * 1000 - now) / ONE_DAY);
    const color = getColor(daysDiff);
    const expired = hasExpired(daysDiff);
    const toastText = expired ? ` Ended at ${date.toDateString()}` : ` Ends at ${date.toDateString()}`;
    const daysLeftText = expired ? `Ended ${Math.abs(daysDiff)} Days(s) ago` : `${daysDiff} Day(s) left`;

    return { color, toastText, daysLeftText };
};

export const DeviceActivationType = ({
    product,
    ends_at,
    color,
}: {
    product: Product;
    ends_at?: number;
    color?: string;
}) => {
    const { color: innerColor, daysLeftText, toastText } = getEndsAtTextInfo(ends_at);

    return (
        <>
            <Tooltip
                sx={{ display: "inline-block", cursor: "help" }}
                title={`This product lets you used upto ${product.allowed_workers} workers per device` + toastText}
            >
                <Typography color={color ? color : "green"}>
                    {product.name}

                    {daysLeftText && (
                        <Typography
                            fontSize={"12px"}
                            variant="body2"
                            component={"span"}
                            display={"inline"}
                            sx={{ color: innerColor, marginLeft: "2px" }}
                        >
                            ({daysLeftText})
                        </Typography>
                    )}
                </Typography>
            </Tooltip>
        </>
    );
};
export const DeviceActivationTypeSmall = ({ product, color }: { product: Product; color?: string }) => {
    const { getShortName } = useProducts();

    return (
        <>
            <Tooltip
                sx={{ display: "inline-block", cursor: "help" }}
                title={`${product.name} lets you used upto ${product.allowed_workers} Worker(s) per device`}
            >
                <Typography fontWeight={"bold"} color={color ? color : "green"}>
                    [{getShortName(product)[0]}]
                </Typography>
            </Tooltip>
        </>
    );
};
