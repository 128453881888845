import { LoadingButton } from "@mui/lab";
import { Button, Container } from "@mui/material";
import React from "react";

interface ActionButtonsSectionProps {
    handleOpenDeleteDeviceModal: () => void;
    handleSubmit: () => void;
    isLoading: boolean;
    disableButtons: boolean;
}

export const ActionButtonsSection: React.FC<ActionButtonsSectionProps> = ({
    handleOpenDeleteDeviceModal,
    handleSubmit,
    isLoading,
    disableButtons,
}) => (
    <>
        <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
                disabled={disableButtons}
                sx={{ mr: 1 }}
                variant="contained"
                color="error"
                onClick={() => handleOpenDeleteDeviceModal()}
            >
                Remove Device
            </Button>
            <LoadingButton
                disabled={disableButtons}
                loading={isLoading}
                type="submit"
                variant="contained"
                onClick={() => handleSubmit()}
            >
                Save Changes
            </LoadingButton>
        </Container>
    </>
);
