import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { footerStyle } from "./style";
import { useColorMode } from "@lib/provider";
import { toTitleCase } from "@lib/utils";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {"Copyright © "}
            <Link color="inherit" href="https://pokemod.dev/">
                The Pokemod Group
            </Link>{" "}
            {new Date().getFullYear()}.
        </Typography>
    );
}

const StickyFooter: React.FC = React.memo(() => {
    const { product } = useColorMode();

    return (
        <Box component="footer" sx={footerStyle}>
            <Container maxWidth="sm">
                <Typography variant="body1">Pokemod {toTitleCase(product)}</Typography>
                <Copyright />
            </Container>
        </Box>
    );
});

export default StickyFooter;
