import { UseFormSetError } from "react-hook-form";
import { AuthErrorType } from "..";

export const mapError = (msg: string, setError: UseFormSetError<AuthErrorType>): boolean => {
    const error_field: ["email", "password"] = ["email", "password"];
    let isField = false;

    error_field.forEach((field) => {
        if (msg.includes(field)) {
            setError(field, {
                type: "api",
                message: msg,
            });
            isField = true;
        }
    });

    return !isField;
};
