import { AxiosResponse } from "axios";
import axiosInstance from "@lib/axios";
import {
    BatchActionActivateLicense,
    BatchActionDeactivateLicense,
    BatchMethodUpdate,
    BatchMethodResponse,
    BatchMethodUpdateInfo,
    DeviceCustomProductActivateOutModel,
} from "../types";

export const batchMethodDelete = async (device_ids: number[]): Promise<void> => {
    await axiosInstance.post("/device/batchDelete", { device_ids });
};

export const batchMethodUpdate = async (data: BatchMethodUpdate): Promise<void> => {
    await axiosInstance.post("/device/batchEdit", { ...data });
};
export const batchMethodUpdateInfo = async (input: BatchMethodUpdateInfo): Promise<BatchMethodResponse> => {
    const { data }: AxiosResponse<BatchMethodResponse> = await axiosInstance.post("/device/batchInfoEdit", input);

    return data;
};

export const batchActionDeactivateLicense = async ({
    device_ids,
}: BatchActionDeactivateLicense): Promise<BatchMethodResponse> => {
    const { data }: AxiosResponse<BatchMethodResponse> = await axiosInstance.post("/device/batchDeactivate", {
        device_ids,
    });

    return data;
};

export const batchActionActivateLicense = async ({
    device_ids,
    product_id,
}: BatchActionActivateLicense): Promise<BatchMethodResponse> => {
    const { data }: AxiosResponse<BatchMethodResponse> = await axiosInstance.post("/device/batchActivate", {
        device_ids,
        product_id,
    });

    return data;
};

export const batchActionActivateCustomLicense = async (
    input: BatchActionActivateLicense & {
        workers: number;
    }
) => {
    const { data }: AxiosResponse<DeviceCustomProductActivateOutModel> = await axiosInstance.post(
        "/device/batchActivateCustom",
        input
    );

    return data;
};
