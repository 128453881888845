import { StyleType } from "@themes";

export const DeviceListContainerStyle: StyleType = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "space-evenly",
    justifyContent: "flex-start",
    position: "relative",
    minWidth: "100%",
    minHeight: "80vh",
};

export const deviceLayoutStyle: StyleType = {
    display: "flex",
    flexDirection: "column",
};
