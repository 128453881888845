import { StyleType } from "@themes";

export const ProfileLayoutStyle: StyleType = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const ProfileCardContainerStyle: StyleType = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // alignItems: "center",
};

export const ProfileCardLayoutStyle: StyleType = {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
};

export const ProfileAvatarStyle: StyleType = {
    bgcolor: "secondary.main",
    width: 56,
    height: 56,
    fontSize: 48,
    mb: "5px",
};

export const ProfileCardHeaderStyle: StyleType = {
    mb: "25px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
};
