import { Checkbox, IconButton, Skeleton, TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";

interface DeviceTableSkeletonProps {
    isSmallScreen: boolean;
}

export const DeviceTableSkeleton: React.FC<DeviceTableSkeletonProps> = ({ isSmallScreen }) => (
    <>
        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox color="primary" />
            </TableCell>
            <TableCell align="left">
                <Skeleton />
            </TableCell>
            {!isSmallScreen && (
                <TableCell align="left">
                    <Skeleton />
                </TableCell>
            )}
            <TableCell align="right">
                <Tooltip title="Edit" sx={{ display: "inline-block" }}>
                    <IconButton>
                        <Skeleton />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete" sx={{ display: "inline-block" }}>
                    <IconButton>
                        <Skeleton />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Manage Device" sx={{ display: "inline-block" }}>
                    <IconButton>
                        <Skeleton />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    </>
);
