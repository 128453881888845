import { extend, useThree, useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

export const CameraControl: React.FC = () => {
    const {
        camera,
        gl: { domElement },
    } = useThree();

    const controls = useRef();

    useFrame(() => {
        if (controls.current) {
            (controls.current as any).update();
        }
    });

    return (
        <orbitControls
            ref={controls}
            args={[camera, domElement]}
            enableZoom={false}
            maxAzimuthAngle={Math.PI / 2}
            maxPolarAngle={Math.PI}
            minAzimuthAngle={-Math.PI / 2}
            minPolarAngle={0}
        />
    );
};
