import React from "react";
import Image from "next/image";
import { Box } from "@mui/system";
import { CashCointainer } from "..";
import styles from "../style/Check.module.css";

interface CashProps {}

export const Cash: React.FC<CashProps> = () => (
    <>
        <Box sx={CashCointainer}>
            <Image
                priority
                className={styles.bounce}
                width={290}
                height={290}
                src="/static/asset/money.png"
                alt="cash"
            />
        </Box>
    </>
);
