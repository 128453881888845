import { useRouter } from "next/dist/client/router";
import { useEffect, useState } from "react";
import { getToken } from "../lib/utils";

const PATH_TO_IGNORE = ["/register", "/login"];

export const useIsAuth = (): {
    user: boolean;
} => {
    const [user, setUser] = useState<boolean>(false);
    const { route, pathname, push, query } = useRouter();

    useEffect(() => {
        const loggedInUser = Boolean(getToken("refresh_token"));

        if (!loggedInUser && !PATH_TO_IGNORE.includes(pathname)) {
            // eslint-disable-next-line no-unused-expressions
            push("/login");
        }
        setUser(loggedInUser);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    return { user };
};
