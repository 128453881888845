import React, { useEffect, useState } from "react";
import Image from "next/image";
import { Box, useTheme } from "@mui/material";
import styles from "../style/crown.module.css";
import { SpinnerCrownContainer } from "..";

interface SpinningCrownProps {
    setBoundingRect: string;
}

export const SpinningCrown: React.FC<SpinningCrownProps> = ({ setBoundingRect }) => {
    const [changeColor, setChangeColor] = useState(false);
    const { palette } = useTheme();

    useEffect(() => {
        setTimeout(() => {
            setChangeColor(true);
        }, 2400);

        return () => {
            setChangeColor(false);
        };
    }, []);

    return (
        <>
            <Box sx={{ ...SpinnerCrownContainer, animation: setBoundingRect }}>
                <div className={styles.hide}>
                    <Image layout="fill" priority src={`/static/asset/crown_${palette.mode}.svg`} alt="crown" />
                </div>
            </Box>

            <div className={styles.backdropContainer}>
                {!changeColor && (
                    <>
                        <div className={`${styles.big} ${palette.mode === "light" ? styles.bigLight : ""}`} />
                        <div className={`${styles.small} ${palette.mode === "light" ? styles.smallLight : ""}`} />
                    </>
                )}
            </div>
        </>
    );
};
