import React from "react";
import { CustomSnackBar } from "@components/Shared";
import { useNotification } from "..";

interface NotificationProps {}

export const Notification: React.FC<NotificationProps> = () => {
    const {
        notification: { isOpen, type, message },
        removeNotification,
    } = useNotification();
    const handleClose = () => {
        removeNotification();
    };

    return (
        <>
            <CustomSnackBar type={type} open={isOpen} message={message} handleClose={handleClose} />
        </>
    );
};
