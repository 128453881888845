import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Tab } from "@mui/material";
import React from "react";
import { useColorMode } from "@lib/provider";
import { MobileThemeToggle } from "@components/Shared";

interface ThemeSwitcherProps {}

export const ThemeSwitcherMobile: React.FC<ThemeSwitcherProps> = () => {
    const { color: mode, toggleColorMode } = useColorMode();

    return (
        <>
            <MobileThemeToggle checked={mode === "dark"} handleChange={() => toggleColorMode()} />
        </>
    );
};
export const ThemeSwitcherDesktop: React.FC<ThemeSwitcherProps> = () => {
    const { color: mode, toggleColorMode } = useColorMode();

    return (
        <>
            <Tab
                label={mode}
                icon={mode === "dark" ? <DarkModeIcon /> : <LightModeIcon />}
                onClick={() => {
                    toggleColorMode();
                }}
                component="span"
            />
        </>
    );
};
