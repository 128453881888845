import { Box } from "@mui/system";
import React from "react";
import { BlobCanvas } from "@features/payments";
import { BlobContainer, blobContainerSize } from "..";
import styles from "../style/Check.module.css";

interface MessageBlobProps {}

export const AnimatedBlob: React.FC<MessageBlobProps> = () => (
    <>
        <Box sx={BlobContainer}>
            <Box className={styles.bounce} sx={{ ...blobContainerSize, position: "relative" }}>
                <BlobCanvas />
            </Box>
        </Box>
    </>
);
