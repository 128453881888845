import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Cash } from ".";
import { BlobCashContainer } from "..";
import { AnimatedBlob } from "./AnimatedBlob";

interface FaliureComponentProps {}

export const AnimatedCashBlobComponent: React.FC<FaliureComponentProps> = () => {
    const [swapItems, setSwapItems] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSwapItems(true);
        }, 3600);

        return () => {
            setSwapItems(false);
            clearTimeout(timeout);
        };
    }, []);

    return (
        <Box sx={{ width: "100%", ...BlobCashContainer, flexDirection: swapItems ? "row-reverse" : "row" }}>
            <Cash />
            <AnimatedBlob />
        </Box>
    );
};
