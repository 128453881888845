import { Box, IconButton } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useInput } from "@mui/base/InputUnstyled";

interface NumericalPickerProps {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    min: number;
    max: number;
}

const StyledInputElement = styled("input")(
    () => `
    font-size: 1.3rem;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    line-height: 1;
    height:100%;
    width:52px;
    color: currentColor;
    padding: 8px 8px;
    transition: all 150ms ease;
    text-align: center;
    outline: none;
    box-shadow: none;
    border:none;
    background:none;
   
  
    &:focus {
      outline: none;
    }
  `
);
const CustomInput = React.forwardRef(
    (props: React.InputHTMLAttributes<HTMLInputElement>, ref: React.ForwardedRef<HTMLInputElement>) => {
        const { getRootProps, getInputProps } = useInput(props, ref);

        return (
            <span {...getRootProps()}>
                <StyledInputElement type="number" min={1} max={999} {...props} {...getInputProps()} />
            </span>
        );
    }
);

export const NumericalPicker: React.FC<NumericalPickerProps> = ({ value, setValue, min, max }) => {
    const handleChange = (_value: number) => {
        if (_value > max) {
            setValue(999);
        } else if (_value < min || Number.isNaN(_value)) {
            setValue(1);
        } else {
            setValue(_value);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <IconButton color="primary" disabled={value <= min} onClick={() => handleChange(value - 1)}>
                <RemoveCircleOutlineIcon sx={{ width: "28px", height: "28px" }} />
            </IconButton>
            <CustomInput
                onChange={(e) => handleChange(parseInt(e.currentTarget.value, 10))}
                value={value}
                aria-label="Numerical-picker"
            />
            <IconButton color="primary" disabled={value >= max} onClick={() => handleChange(value + 1)}>
                <AddCircleOutlineIcon sx={{ width: "28px", height: "28px" }} />
            </IconButton>
        </Box>
    );
};
