import React from "react";
import Box from "@mui/material/Box";

interface AuthFormBodyProps {
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const AuthFormBody: React.FC<AuthFormBodyProps> = ({ handleSubmit, children }) => (
    <>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {children}
        </Box>
    </>
);

export default AuthFormBody;
