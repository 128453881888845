import { UseFormSetError } from "react-hook-form";
import { ChangePasswordFormInput } from "..";

export const mapError = (msg: string, setError: UseFormSetError<ChangePasswordFormInput>): boolean => {
    const error_field = ["old_password", "new_password", "password"];
    let isField = false;

    error_field.forEach((field) => {
        if (msg.toLowerCase().includes(field)) {
            setError("old_password", {
                type: "api",
                message: msg,
            });
            isField = true;
        }
    });

    return !isField;
};
