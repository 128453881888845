import { Paper, Box } from "@mui/material";
import React, { useState } from "react";
import { AuthFormContainerPaperStyle, AuthFormContainerStyle } from "../../style";

interface AuthFormContainerProps {}

const AuthFormContainer: React.FC<AuthFormContainerProps> = ({ children }) => {
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const mouseEnter: React.MouseEventHandler<HTMLDivElement> | undefined = (event) => {
        if (event.currentTarget) {
            setIsMouseOver(true);
        }
    };
    const mouseLeave: React.MouseEventHandler<HTMLDivElement> | undefined = (event) => {
        if (event.currentTarget) {
            setIsMouseOver(false);
        }
    };

    return (
        <Box sx={AuthFormContainerStyle}>
            <Paper
                elevation={3}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                sx={{
                    ...AuthFormContainerPaperStyle,
                    boxShadow: isMouseOver
                        ? "rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px"
                        : "",
                }}
            >
                {children}
            </Paper>
        </Box>
    );
};

export default AuthFormContainer;
