import { ColorModes } from "../provider";

type SortPrefType = { sort?: string; row?: number; page?: number };

const KEY_SLUG = "@atlas/";
const COLOR_MODE = `${KEY_SLUG}theme`;
const EMAIL = `${KEY_SLUG}email`;
const HAS_SEEN_CROWN = `${KEY_SLUG}has_seen_crown`;
const SORT_PREFS = `${KEY_SLUG}sort_prefs`;
const SAW_TIP = `${KEY_SLUG}saw_tip`;

type tokens = "auth_token" | "refresh_token";

export const getToken = (token_type: tokens): string | null => localStorage.getItem(KEY_SLUG + token_type);

export const setToken = (token_type: tokens, data: string): void => {
    localStorage.setItem(KEY_SLUG + token_type, data);
};

export const setSawTip = () => {
    localStorage.setItem(SAW_TIP, "true");
};

export const getSawTip = () => {
    return Boolean(localStorage.getItem(SAW_TIP));
};

export const removeToken = (token_type: tokens): void => {
    localStorage.removeItem(KEY_SLUG + token_type);
};

export const setColor = (mode: ColorModes): void => {
    localStorage.setItem(COLOR_MODE, JSON.stringify(mode));
};

export const getColor = (): ColorModes => {
    const theme = localStorage.getItem(COLOR_MODE);

    if (!theme) {
        return { color: "light", product: "atlas" };
    }

    return JSON.parse(theme) as ColorModes;
};

export const setEmail = (email: string): void => {
    localStorage.setItem(EMAIL, email);
};

export const removeEmail = (): void => localStorage.removeItem(EMAIL);

export const getEmail = (): string => localStorage.getItem(EMAIL) || "noEmail???";

export const setHasSeenCrown = (): void => {
    localStorage.setItem(`${HAS_SEEN_CROWN}/${getEmail()}`, "true");
};

export const getHasSeenCrown = (): boolean => Boolean(localStorage.getItem(`${HAS_SEEN_CROWN}/${getEmail()}`));

export const getSortPrefs = (): SortPrefType | null => {
    const prefs = localStorage.getItem(SORT_PREFS);

    if (!prefs) return null;

    return JSON.parse(prefs);
};

export const setSortPrefs = (prefs: SortPrefType): void => {
    const oldPref = getSortPrefs() || {};

    localStorage.setItem(SORT_PREFS, JSON.stringify(Object.assign(oldPref, prefs)));
};
