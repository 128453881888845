/* eslint-disable require-atomic-updates */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "next/dist/client/router";
import axiosInstance from ".";
import { removeAuthTokens } from "../utils";
import { getToken, setToken } from "../utils/localStorageService";
// import { get_token, set_token } from "";

type AuthResponse = AxiosResponse<{
    refresh_token: string;
    auth_token: string;
}>;

const REFRESH_TOKENS_ERROR_CODES = [401, 422, 403];

let isRefreshing = false;
let refreshQueue: ((token: string) => void)[] = [];

const addToQueue = (callback: (token: string) => void) => {
    refreshQueue.push(callback);
};

const processQueue = (token: string) => {
    refreshQueue.forEach((callback) => callback(token));

    refreshQueue = [];
};

export const onResponseReject = async (error: AxiosError): Promise<AxiosResponse<never>> => {
    const originalRequest = error.config;

    if (
        REFRESH_TOKENS_ERROR_CODES.includes(error?.response?.status as number) &&
        originalRequest?.url?.includes("refresh")
    ) {
        isRefreshing = false;
        // eslint-disable-next-line no-unused-expressions
        router.query
            ? router.replace(`/login?next=${router.asPath}`)
            : router.replace(`/login?next=${router.pathname}`);
        removeAuthTokens();

        return Promise.reject(error);
    }

    if (error.config && error.response && error.response.status === 401) {
        const refresh_token = getToken("refresh_token");

        if (!refresh_token) return Promise.reject(error);
        // eslint-disable-next-line no-negated-condition
        if (isRefreshing) {
            return new Promise<AxiosResponse<never>>((resolve) => {
                addToQueue((token: string) => {
                    originalRequest.headers!.Authorization = `Bearer ${token}`;
                    resolve(axiosInstance(originalRequest));
                });
            });
        } else {
            isRefreshing = true;
            try {
                const res: AuthResponse = await axiosInstance.post("/auth/user/refresh", {
                    refresh_token,
                });

                if (res.status === 200) {
                    setToken("refresh_token", res.data.refresh_token);
                    setToken("auth_token", res.data.auth_token);
                    originalRequest.headers!.Authorization = `Bearer ${res.data.auth_token}`;

                    processQueue(res.data.auth_token);

                    return axiosInstance(originalRequest);
                }
            } catch (refreshError) {
                isRefreshing = false;

                removeAuthTokens();
                processQueue("");

                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }
    }

    return Promise.reject(error);
};

export const onRequest = (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
    const token = getToken("auth_token");

    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
};
