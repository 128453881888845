import { keyframes } from "@mui/system";
import { StyleType } from "@themes";

export const blobContainerSize: StyleType = {
    width: "216px",
    height: "216px",
};

const scale = keyframes`

    0% {
        transform: none;
    }
    16.6% {
        transform: scale3d(0, 0, 0);
    }
    45% {
        transform: scale3d(0, 0, 0);
    }
    65%{
        transform: scale3d(1.3, 1.3, 1.3);
        opacity:1;
    }
    75%{
        transform: scale3d(1.3, 1.3, 1.3);
        opacity:1;
    }
    100%{
        transform: scale3d(0, 0, 0)
    }

`;

const bottomSlide = keyframes`

    from {
        opacity: 0;
        transform: translateY(15px);
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
`;

const fadeIn = keyframes`
    0% {opacity : 0;}
    100% {opacity : 1}
`;

export const BlobContainer: StyleType = {
    ...blobContainerSize,
    animation: `${scale} 2.5s 2.5s ease-in-out forwards, ${fadeIn} 0.7s linear `,
};

export const CashCointainer: StyleType = {
    ...blobContainerSize,
    animation: `${scale} 2.5s 2.5s ease-in-out forwards, ${fadeIn} 0.7s linear `,
};

export const TypographyComponent: StyleType = {
    animation: `${bottomSlide} 0.4s ease-in-out forwards`,
    opacity: 0,
};

export const ButtonComponent: StyleType = {
    animation: `${bottomSlide} 0.4s 0.15s ease-in-out forwards`,
    opacity: 0,
};

export const BlobCashContainer: StyleType = {
    flex: 1,
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
};
export const CheckoutLayoutContainer: StyleType = {
    flex: 1,
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
};
