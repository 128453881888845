import { Device, LicenseStatus, SortType } from "../types";

const ascending = (a: Device, b: Device): number => {
    const nameA = a.device_name.toLowerCase();
    const nameB = b.device_name.toLowerCase();

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
};
const descending = (device1: Device, device2: Device): number => {
    const name1 = device1.device_name.toLowerCase();
    const name2 = device2.device_name.toLowerCase();

    if (name1 < name2) {
        return 1;
    }
    if (name1 > name2) {
        return -1;
    }

    return 0;
};

const active = (device1: Device, device2: Device): number => {
    const status1 = device1.license_status;
    const status2 = device2.license_status;

    if (status1 === LicenseStatus.ACTIVE && status2 === LicenseStatus.ACTIVE) {
        return 0;
    }
    if (status1 === LicenseStatus.ACTIVE) {
        return -1;
    }

    return 1;
};

const deactive = (device1: Device, device2: Device): number => {
    const status1 = device1.license_status;
    const status2 = device2.license_status;

    if (status1 === LicenseStatus.INACTIVE && status2 === LicenseStatus.INACTIVE) {
        return 0;
    }
    if (status1 === LicenseStatus.INACTIVE) {
        return -1;
    }

    return 1;
};

export const sortMyDevices = (list: Device[], type: SortType): Device[] => {
    switch (type) {
        case "NAME_ASCE":
            return list.sort(ascending);
        case "NAME_DESC":
            return list.sort(descending);
        case "LISC_ACTIVE":
            return list.sort(active);
        case "LISC_DEACTIVE":
            return list.sort(deactive);
        default:
            return list;
    }
};
