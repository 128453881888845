import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import { instanceTableContainerStyle } from "../style";

interface InstanceTableContainerProps {}

export const InstanceTableContainer: React.FC<InstanceTableContainerProps> = ({ children }) => (
    <>
        <Paper>
            <TableContainer sx={instanceTableContainerStyle}>
                <Table stickyHeader size="small" aria-label="instance table">
                    {children}
                </Table>
            </TableContainer>
        </Paper>
    </>
);
