import { useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import SimplexNoise from "simplex-noise";
import { Vector3 } from "three";
import { blobRandomizer, getEmail } from "@lib/utils";

interface UserBlobProps {}

export const UserBlob: React.FC<UserBlobProps> = () => {
    const { color, spike, shine } = blobRandomizer(getEmail());
    const meshRef = useRef();
    const geoRef = useRef();
    const simplex = new SimplexNoise(getEmail());

    useFrame(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        animate();
    });
    function animate() {
        // eslint-disable-next-line no-multi-assign
        if (meshRef.current) (meshRef.current as any).rotation.x = (meshRef.current as any).rotation.y += 0.001;

        if (geoRef.current) {
            const geometry = geoRef.current as any;
            const vertex = new Vector3();

            const time = performance.now() * 0.0005;

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < geometry.attributes.position.count; i++) {
                vertex.fromBufferAttribute(geometry.attributes.position, i);
                vertex
                    .normalize()
                    .multiplyScalar(
                        1 + 0.3 * simplex.noise3D(vertex.x * spike, vertex.y * spike, vertex.z * spike + time)
                    );
                geometry.attributes.position.setXYZ(i, vertex.x, vertex.y, vertex.z);
            }

            geometry.computeVertexNormals();
            geometry.attributes.position.needsUpdate = true;
        }
    }

    return (
        <mesh ref={meshRef} scale={2}>
            <sphereGeometry args={[0.8, 128, 128]} ref={geoRef} />
            <meshPhongMaterial shininess={shine} color={color} />
        </mesh>
    );
};
