export const copyToClipboard = (data: string): void => {
    // Deprecated only use it for IE
    // let textField = document.createElement("textarea");
    // textField.innerText = data;
    // document.body.appendChild(textField);
    // textField.select();
    // document.execCommand("copy");
    // textField.remove();
    navigator.clipboard.writeText(data);
};
