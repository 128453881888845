import { Component, ErrorInfo, ReactNode } from "react";
import { ErrorPage } from ".";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
}

const defaultState = {
    hasError: false,
    error: undefined,
};

export class GlobalErrorBoundary extends Component<Props, State> {
    public state: State = defaultState;

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorPage error={this.state.error} />;
        }

        return this.props.children;
    }
}
