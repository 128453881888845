import { ErrorSnackbarAction, ServerErrorResponse } from "@types";

export type ErrorSnackbarDispatcher = {
    error: unknown;
    statusCodes: number[];
    dispatcher: React.Dispatch<ErrorSnackbarAction>;
};

export const errorSnackbarDispatcher = ({ error, statusCodes, dispatcher }: ErrorSnackbarDispatcher): void => {
    const errorResponse = (error as any).response?.data as ServerErrorResponse;

    if (!errorResponse) {
        dispatcher({ type: "SET", message: (error as any).message });

        return;
    }
    if (statusCodes.includes(errorResponse.status)) {
        dispatcher({ type: "SET", message: errorResponse.message });
    }
};
