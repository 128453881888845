/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ModalAction, ModalState } from "@types";

export const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
    switch (action.type) {
        case "EDIT_BATCH":
            return {
                ...state,
                edit_batch: true,
            };
        case "EDIT":
            return {
                ...state,
                edit: true,
            };
        case "DELETE_BATCH":
            return {
                ...state,
                delete_batch: true,
            };
        case "DELETE":
            return {
                ...state,
                delete: true,
            };
        case "RESET_EDIT_BATCH":
            return {
                ...state,
                edit_batch: false,
            };
        case "RESET_EDIT":
            return {
                ...state,
                edit: false,
            };
        case "RESET_DELETE_BATCH":
            return {
                ...state,
                delete_batch: false,
            };
        case "RESET_DELETE":
            return {
                ...state,
                delete: false,
            };
        case "LICENSE_BATCH":
            return {
                ...state,
                license_batch: true,
            };
        case "RESET_LICENSE_BATCH":
            return {
                ...state,
                license_batch: false,
            };

        default:
            return { ...state };
    }
};
