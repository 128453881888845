/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ErrorSnackbarAction, ErrorSnackbarState } from "@types";

export const errorSnackbarReducer = (state: ErrorSnackbarState, action: ErrorSnackbarAction): ErrorSnackbarState => {
    switch (action.type) {
        case "SET":
            return {
                isOpen: true,
                message: action.message,
            };
        case "RESET":
            return {
                isOpen: false,
                message: "",
            };
        default:
            return { ...state };
    }
};
