import { useTheme } from "@mui/material";
import React from "react";

interface CrownProps {
    fill?: string;
}

export const Crown: React.FC<CrownProps> = ({ fill }) => {
    const { palette } = useTheme();
    const color = palette.mode === "dark" ? "#FFFFFF" : "#757575";

    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 96.000000 96.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill={fill || color} stroke="none">
                <path
                    d="M450 825 c-7 -9 -10 -25 -6 -40 5 -21 -3 -40 -45 -98 -28 -40 -55
                    -76 -59 -80 -4 -5 -55 6 -113 24 -84 26 -107 37 -109 52 -4 29 -34 45 -55 31
                    -32 -20 -17 -68 24 -80 2 0 16 -61 33 -135 16 -74 32 -144 35 -156 l5 -23 320
                    0 320 0 5 23 c3 12 19 82 35 156 17 74 31 135 33 135 41 12 56 60 25 80 -23
                    14 -52 -2 -56 -31 -2 -16 -24 -26 -105 -52 -56 -17 -105 -31 -109 -31 -3 0
                    -32 36 -62 80 -45 63 -55 85 -50 105 7 29 -10 55 -36 55 -10 0 -23 -7 -30 -15z"
                />
                <path d="M160 200 l0 -40 320 0 320 0 0 40 0 40 -320 0 -320 0 0 -40z" />
            </g>
        </svg>
    );
};
