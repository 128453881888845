import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";

export interface FormButtonProps {
    text: string;
    isLoading?: boolean;
}

export const FormButton: React.FC<FormButtonProps> = ({ text, isLoading = false }) => (
    <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, borderRadius: "25px" }}
        loading={isLoading}
    >
        {text}
    </LoadingButton>
);
