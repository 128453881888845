import { useColorMode } from "@lib/provider";
import { getSawTip, setSawTip } from "@lib/utils";
import { MenuItem, Select, Tooltip } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../Shared";

export const HeaderBrandName: React.FC = () => {
    const { product, changeProduct } = useColorMode();
    const [showTip, setShowTip] = useState(false);
    const [open, setOpen] = useState(false);
    const { route, push } = useRouter();

    useEffect(() => {
        setShowTip(!getSawTip());
    }, []);

    return (
        <>
            <Tooltip id={"popper"} open={showTip} title={"Change products here"} sx={{ zIndex: 1111 }} arrow>
                <Toolbar>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product}
                        label="Product"
                        MenuProps={{
                            onMouseEnter() {
                                setShowTip(false);
                                setSawTip();
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                            fontWeight: 500,
                            fontSize: "1.25rem",
                            color: "white",
                            "& .MuiSelect-icon": {
                                color: "white", // Change the color here
                            },
                        }}
                        onChange={(e) => {
                            if (route.includes("/device/[id]")) {
                                setOpen(true);
                            } else {
                                changeProduct(e.target.value as "atlas" | "aegis");
                            }
                            setSawTip();
                        }}
                    >
                        <MenuItem value={"atlas"}>Atlas</MenuItem>
                        <MenuItem value={"aegis"}>Aegis</MenuItem>
                    </Select>
                </Toolbar>
            </Tooltip>
            <ConfirmationModal
                title="This will take you back to homepage"
                confirmButton={() => {
                    setOpen(false);
                    push("/devices");
                }}
                open={open}
                handleCloseModal={() => setOpen(false)}
                confirmButtonName="Ok"
            />
        </>
    );
};
