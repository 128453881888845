import { keyframes } from "@mui/material";

// It works tho
export const createAnimationAndPosition = (current: HTMLSpanElement | null): string | undefined => {
    const boundingRect = current?.getBoundingClientRect().toJSON();

    if (boundingRect) {
        const to = Object.entries(boundingRect)
            .map(([k, v], index) => `${k}:${v}${index > 1 ? "px" : ""}`)
            .join(";");

        const shrinkAndPlace = keyframes`
                0% {
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
                100%{
                    ${to};
                    transform:none;
                }
            `;

        return `${shrinkAndPlace} 0.4s 2s ease-out infinite forwards`;
    }

    return undefined;
};
