import { Skeleton, TableCell, TableRow, Tooltip, IconButton } from "@mui/material";
import React from "react";

interface InstanceTableSkeletonProps {}
export const InstancesTableSkeleton: React.FC<InstanceTableSkeletonProps> = () => (
    <TableRow>
        <TableCell sx={{ fontSize: "16px" }}>
            <Skeleton width={8} />
        </TableCell>
        <TableCell align="center" sx={{ fontSize: "16px" }}>
            <Skeleton />
        </TableCell>
        <TableCell align="right">
            <Tooltip title="Edit Status" sx={{ display: "inline-block" }}>
                <IconButton>
                    <Skeleton width={10} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete Instance" sx={{ display: "inline-block" }}>
                <IconButton>
                    <Skeleton width={10} />
                </IconButton>
            </Tooltip>
        </TableCell>
    </TableRow>
);
