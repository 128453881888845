import { Canvas } from "@react-three/fiber";
import React from "react";
import { CameraControl, UserBlob } from ".";

interface BlobCanvasProps {}

export const BlobCanvas: React.FC<BlobCanvasProps> = () => (
    <>
        <Canvas>
            <CameraControl />
            <ambientLight intensity={0.05} />
            <directionalLight intensity={0.5} position={[500, 500, 200]} />
            <UserBlob />
        </Canvas>
    </>
);
