import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Box, Checkbox, FormControlLabel, Icon, InputAdornment, Tooltip } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { FormState, UseFormRegister } from "react-hook-form";
import { FormInputField } from "@components/Form";
import { getHasSeenCrown, setHasSeenCrown } from "@lib/utils";
import { FormSkeleton } from ".";
import { ConfigFormContainerStyle, DeviceEditConfig, flexColumn } from "..";
import { createAnimationAndPosition } from "../utils";
import { Crown } from "./Crown";
import { SpinningCrown } from "./SpinningCrown";
import { useColorMode } from "@lib/provider";

interface DeviceConfigFormProps {
    register: UseFormRegister<DeviceEditConfig>;
    formState: FormState<DeviceEditConfig>;
    showSkeleton: boolean;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
    showWorkersField: boolean;
}

export const DeviceConfigForm: React.FC<DeviceConfigFormProps> = ({
    register,
    formState: { errors },
    showSkeleton,
    isChecked,
    setIsChecked,
    showWorkersField,
}) => {
    const [showCrown, setShowCrown] = useState<boolean>(false);
    const andromedaRef = useRef<HTMLSpanElement>(null);
    const { product } = useColorMode();
    const handleSetCrown = (checked: boolean) => {
        switch (getHasSeenCrown()) {
            case true:
                setIsChecked(checked);
                break;
            case false:
                if (checked) {
                    setShowCrown(true);
                    setHasSeenCrown();
                    setTimeout(() => {
                        setShowCrown(false);
                        setIsChecked(true);
                    }, 2425);
                } else {
                    setIsChecked(false);
                }
                break;
            default:
                break;
        }
    };
    const setPosition = useCallback(
        () => createAnimationAndPosition(andromedaRef.current) || "placeholder",
        [andromedaRef]
    );
    // console.log(andromedaRef.current?.getBoundingClientRect());

    if (showSkeleton) {
        return <FormSkeleton />;
    }

    return (
        <>
            <Box sx={ConfigFormContainerStyle}>
                <Box component="form" sx={flexColumn}>
                    <FormInputField
                        variant="filled"
                        register={register("device_name")}
                        required={false}
                        label="Name"
                        error={Boolean(errors.device_name?.message)}
                        helperText={errors.device_name?.message}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={isChecked ? "This device uses local config" : ""}>
                                        <Icon
                                            sx={{
                                                width: "24px",
                                                height: "24px",
                                                cursor: isChecked ? "pointer" : "text",
                                            }}
                                            ref={andromedaRef}
                                            aria-label="device uses local config"
                                        >
                                            {isChecked ? <Crown /> : null}
                                        </Icon>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                        disabled={isChecked}
                    />
                    <FormInputField
                        variant="filled"
                        register={register("rdm_endpoint")}
                        label={`${product === "atlas" ? "RDM" : "Rotom"} Url`}
                        error={Boolean(errors.rdm_endpoint?.message)}
                        helperText={errors.rdm_endpoint?.message}
                        disabled={isChecked}
                        required={false}
                    />
                    <FormInputField
                        variant="filled"
                        register={register("auth_bearer")}
                        label="Auth Bearer"
                        error={Boolean(errors.auth_bearer?.message)}
                        helperText={errors.auth_bearer?.message}
                        required={false}
                        disabled={isChecked}
                    />
                    {product === "atlas" && (
                        <FormInputField
                            variant="filled"
                            register={register("ptc_auth_url")}
                            label="Ptc Auth Url"
                            error={Boolean(errors.ptc_auth_url?.message)}
                            helperText={errors.ptc_auth_url?.message}
                            required={false}
                            disabled={isChecked}
                        />
                    )}
                    {showWorkersField && (
                        <FormInputField
                            variant="filled"
                            register={register("workers")}
                            label="Workers"
                            inputMode="numeric"
                            error={Boolean(errors.workers?.message)}
                            helperText={errors.workers?.message}
                            required={false}
                            disabled={isChecked}
                        />
                    )}
                    <Box>
                        <FormControlLabel
                            sx={{ mt: 1, mr: 0 }}
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    color="primary"
                                    size="medium"
                                    onChange={async (_, change) => {
                                        handleSetCrown(change);
                                    }}
                                />
                            }
                            label="Uses local config"
                        />
                        <Tooltip
                            title={`If checked this device will use the local config provided by the atlas application for ${
                                product === "atlas" ? "RDM" : "Rotom"
                            } url, name, bearer"`}
                        >
                            <QuestionMarkIcon sx={{ position: "relative", width: "16px", height: "16px", top: "0" }} />
                        </Tooltip>
                    </Box>
                </Box>
                {showCrown && <SpinningCrown setBoundingRect={setPosition()} />}
            </Box>
        </>
    );
};
