import { LinearProgress, Typography } from "@mui/material";
import React from "react";
import { ConfirmationModal } from "@components/Shared";
import { useNotification } from "@lib/provider";
import { copyToClipboard } from "@lib/utils";
import { DeviceTokenModalType } from "..";

interface DeviceAuthTokenProps {
    state: DeviceTokenModalType;
    handleCloseTokenModal: () => void;
    handleCloseWarningModal: () => void;
    fetchToken: () => Promise<void>;
}

export const DeviceAuthToken: React.FC<DeviceAuthTokenProps> = ({
    state,
    fetchToken,
    handleCloseTokenModal,
    handleCloseWarningModal,
}) => {
    const { addNotification } = useNotification();

    return (
        <>
            <ConfirmationModal
                open={state.isOpenAuthModal}
                handleCloseModal={() => {
                    handleCloseTokenModal();
                }}
                disableButton={!state.token}
                confirmButton={() => {
                    if (!state.token) return;
                    copyToClipboard(state.token as string);
                    addNotification({ message: "Copied device auth token", type: "success" });
                    handleCloseWarningModal();
                }}
                title="Make sure to copy your device auth token now. You won’t be able to see it again!"
                description={<pre>{state.token || <LinearProgress />}</pre>}
                confirmButtonName="Copy"
                clickAway
            />
            <ConfirmationModal
                open={state.isOpenWarningModal}
                handleCloseModal={() => handleCloseWarningModal()}
                confirmButton={async () => {
                    await fetchToken();
                }}
                title="This will make your previous device auth token obsolete"
                description={
                    <Typography color="orangered">
                        Proceeding further might kill all your active devices and may need an Atlas service restart on
                        those devices
                    </Typography>
                }
                confirmButtonName="Confirm"
                confirmBtnColor="error"
            />
        </>
    );
};
