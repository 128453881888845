import { LoadingButton } from "@mui/lab";
import { MenuItem, Modal, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useRouter } from "next/dist/client/router";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ConfirmationModal, ConfirmationModalStyle, DialogWithInput, SelectButton } from "@components/Shared";
import { useApiError, useNotification } from "@lib/provider";
import {
    batchActionActivateCustomLicense,
    batchActionActivateLicense,
    batchActionDeactivateLicense,
    LicenseAction,
} from "..";
import { BatchMethodResponse, DeviceWorkerChangeModel, LicenseStatus } from "../types";
import { useProducts } from "@hooks";
// import { PRODUCTS, getProductFromId } from "@lib/utils";

interface ManageLicensesProps {
    deviceIds: number[];
    open: boolean;
    handleClose: () => void;
    handleLicenseStatusUpdate: (affected_device_ids: number[], status: LicenseStatus, product_id: string) => void;
    handleCustomLicenseStatusUpdate: (changes: DeviceWorkerChangeModel[], product_id: string) => void;
}

export const ManageLicenses: React.FC<ManageLicensesProps> = ({
    deviceIds,
    handleClose,
    open,
    handleLicenseStatusUpdate,
    handleCustomLicenseStatusUpdate,
}) => {
    const { replace } = useRouter();
    const [action, setAction] = useState<LicenseAction | string>("none");
    const [batchModal, setBatchModal] = useState<{ open: boolean; affected: number }>({ open: false, affected: 0 });
    const { addError } = useApiError();
    const { addNotification } = useNotification();
    const { mutateAsync: deactivate, isLoading: loadingDeactivate } = useMutation(batchActionDeactivateLicense);
    const { mutateAsync: activate, isLoading: loadingActivate } = useMutation(batchActionActivateLicense);
    const { mutateAsync: activateCustom, isLoading: loadingActivateCustom } = useMutation(
        batchActionActivateCustomLicense
    );
    const handleEquals = useCallback((_action: LicenseAction) => action === "none" || action === _action, [action]);
    const { getProductFromId, getDefaultProduct, getFilteredProducts } = useProducts();
    const [product, setProduct] = useState(getDefaultProduct());
    const [isAmountModal, setIsAmountModal] = useState(false);
    const [changeModal, setChangeModal] = useState<{
        open: boolean;
        changes: DeviceWorkerChangeModel[];
        workers: number;
    }>({
        open: false,
        changes: [],
        workers: 0,
    });

    useEffect(() => {
        setProduct(getDefaultProduct());
    }, [getDefaultProduct]);

    const isDesktop = useMediaQuery("(min-width:900px)");
    const handleClick = async (_action: LicenseAction): Promise<void> => {
        if (product.is_individual && _action === "activate") {
            setIsAmountModal(true);

            return;
        }
        setAction(_action);
        try {
            let response: BatchMethodResponse = { affected_device_ids: [], successful: false };

            if (_action === "activate") {
                response = await activate({
                    device_ids: deviceIds,
                    product_id: product.id,
                });
            } else {
                response = await deactivate({
                    device_ids: deviceIds,
                });
            }
            const { affected_device_ids, successful } = response;

            handleLicenseStatusUpdate(
                affected_device_ids,
                _action === "activate" ? LicenseStatus.ACTIVE : LicenseStatus.INACTIVE,
                _action === "activate" ? product.id : ""
            );

            if (affected_device_ids.length < deviceIds.length) {
                handleClose();
                setBatchModal({ open: true, affected: affected_device_ids.length });
            }
            addNotification({
                message: `${successful ? "Updated" : "Failed to update"} licenses for ${
                    affected_device_ids.length || deviceIds.length
                } device(s)`,
                type: successful ? "success" : "error",
            });
        } catch (error) {
            addError({ error });
        }
        setAction("none");
    };

    const customActivate = async (workers: number) => {
        if (!workers) {
            return addNotification({
                message: "Invallid worker amount",
                type: "error",
            });
        }
        try {
            setIsAmountModal(false);
            setAction("activate");
            const { changes } = await activateCustom({ device_ids: deviceIds, product_id: product.id, workers });

            handleCustomLicenseStatusUpdate(changes, product.id);
            const unsuccesful = changes.filter((c) => c.active_instances !== workers);

            if (unsuccesful.length) {
                setChangeModal({ open: true, changes: unsuccesful, workers });
            } else {
                addNotification({
                    message: `Updated ${deviceIds.length - unsuccesful.length} devices with ${workers} Worker(s) each `,
                    type: "success",
                });
            }
        } catch (error) {
            addError({ error });
        }

        setAction("none");
    };

    if (!product) {
        return null;
    }

    const isLoading = loadingActivate || loadingDeactivate || loadingActivateCustom;

    return (
        <>
            <Modal
                sx={{ backdropFilter: "blur(4px)" }}
                open={open}
                onClose={handleClose}
                aria-labelledby="confirmation-modal-title"
                aria-describedby="confirmation-modal-description"
            >
                <Box sx={ConfirmationModalStyle}>
                    <h3 id="confirmation-modal-title">Activate or deactivate licenses for selected devices</h3>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: isDesktop ? "nowrap" : "wrap-reverse",
                        }}
                    >
                        {handleEquals("deactivate") && (
                            // <Box sx={{ m: 1 }}>
                            <LoadingButton
                                loading={isLoading}
                                onClick={async () => {
                                    await handleClick("deactivate");
                                }}
                                sx={{
                                    mx: "4px",
                                    width: isLoading ? "24px" : "250px",
                                    ...(!isDesktop && { mt: 2 }),
                                }}
                                variant={isLoading ? "contained" : "outlined"}
                            >
                                Deactivate
                            </LoadingButton>
                            // </Box>
                        )}
                        {handleEquals("activate") && (
                            // <Box sx={{ m: 1 }}>
                            <SelectButton
                                variant="contained"
                                onClick={async () => {
                                    await handleClick("activate");
                                }}
                                value={product.id}
                                onChange={(v) => setProduct(getProductFromId(v as string))}
                            >
                                {getFilteredProducts().map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        Activate {name}
                                    </MenuItem>
                                ))}
                            </SelectButton>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <LoadingButton onClick={() => handleClose()} sx={{ mt: 2 }}>
                            Close
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
            <ConfirmationModal
                confirmButton={() => {
                    const toBuy = deviceIds.length - batchModal.affected;

                    setBatchModal({ open: false, affected: 0 });
                    replace(`/payment?qty=${toBuy}&product_id=${product.id}`);
                }}
                confirmButtonName="Yes, Please"
                confirmButtonVariant="contained"
                cancleButtonName="No, Thanks"
                // eslint-disable-next-line prettier/prettier
                title={`${batchModal.affected === 0 ? "" : "Only "}${batchModal.affected} out of ${
                    deviceIds.length
                } devices were updated, would you like to buy ${
                    deviceIds.length - batchModal.affected
                } more license(s)?`}
                open={batchModal.open}
                handleCloseModal={() => setBatchModal({ open: false, affected: 0 })}
            />
            <ConfirmationModal
                confirmButton={() => {
                    const toBuy = changeModal.changes.reduce((prev, curr) => {
                        return prev + (changeModal.workers - curr.active_instances);
                    }, 0);

                    setChangeModal({ open: false, changes: [], workers: 0 });

                    replace(`/payment?qty=${toBuy}&product_id=${product.id}`);
                }}
                confirmButtonName="Yes, Please"
                confirmButtonVariant="contained"
                cancleButtonName="No, Thanks"
                // eslint-disable-next-line prettier/prettier
                title={`You're ${changeModal.changes.reduce((prev, curr) => {
                    return prev + (changeModal.workers - curr.active_instances);
                }, 0)} licenses short for activating all the licenses, Would you like to buy more?`}
                open={changeModal.open}
                handleCloseModal={() => setChangeModal({ open: false, changes: [], workers: 0 })}
            />
            <DialogWithInput
                handleClose={() => {
                    setIsAmountModal(false);
                }}
                open={isAmountModal}
                onSubmit={customActivate}
            />
        </>
    );
};
