import { Drawer, Typography, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useRouter } from "next/dist/client/router";
import React, { useState } from "react";
import { logout, LogoutModal } from "@features/auth";
import { useIsAuth } from "@hooks";
import { useApiError } from "@lib/provider";
import { AuthMobile } from "./AuthHeader";
import { HeaderBrandName } from "./HeaderBrandName";
import { HeaderNav } from "./HeaderNav";
import { NotAuthMobile } from "./NotAuthHeader";
import { headerStyle } from "./style";
import { ThemeSwitcherMobile } from "./ThemeSwitcher";
import { useQueryClient } from "react-query";

const Header: React.FC = () => {
    const { user } = useIsAuth();
    const { addError } = useApiError();
    const queryClient = useQueryClient();
    const isDesktop = useMediaQuery("(min-width:900px)");

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isOpenLogout, setIsOpenLogout] = useState(false);

    const router = useRouter();

    const handleMobileMenuClose = () => {
        setDrawerOpen(false);
    };

    const handleMobileMenuOpen = () => {
        setDrawerOpen(true);
    };

    const handleOpenLogoutModal = () => {
        setIsOpenLogout(true);
    };

    const handleCloseLogoutModal = () => {
        setIsOpenLogout(false);
    };

    const handleLogout = async () => {
        try {
            await logout();
            router.push("/login");
            setIsOpenLogout(false);
            queryClient.removeQueries();
            handleMobileMenuClose();
        } catch (error) {
            addError({ error });
        }
    };

    return (
        <AppBar color="primary" enableColorOnDark position="sticky" sx={headerStyle}>
            {isDesktop ? (
                <>
                    <HeaderBrandName />
                    <HeaderNav
                        handleLogout={handleOpenLogoutModal}
                        handleMobileMenuOpen={handleMobileMenuOpen}
                        router={router}
                        user={user}
                        isDrawerOpen={drawerOpen}
                    />
                </>
            ) : (
                <>
                    <Box sx={{ display: "flex" }}>
                        <HeaderNav
                            handleLogout={handleOpenLogoutModal}
                            handleMobileMenuOpen={handleMobileMenuOpen}
                            router={router}
                            user={user}
                            isDrawerOpen={drawerOpen}
                        />
                        <HeaderBrandName />
                    </Box>
                    <ThemeSwitcherMobile />
                </>
            )}

            <Drawer anchor="left" open={drawerOpen} onClose={handleMobileMenuClose}>
                <Box sx={{ width: "50vw" }}>
                    <AppBar
                        color="primary"
                        enableColorOnDark
                        position="relative"
                        sx={{ justifyContent: "space-around", alignItems: "center", flexDirection: "row" }}
                    >
                        <Toolbar>
                            <Typography
                                onClick={() => handleMobileMenuClose()}
                                sx={{ cursor: "pointer" }}
                                variant="h6"
                                component="div"
                            >
                                Atlas
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {user ? (
                        <AuthMobile
                            handleClose={handleMobileMenuClose}
                            handleLogout={handleOpenLogoutModal}
                            pathName={router.pathname}
                        />
                    ) : (
                        <NotAuthMobile handleClose={handleMobileMenuClose} pathName={router.pathname} />
                    )}
                </Box>
            </Drawer>
            <LogoutModal handleClose={handleCloseLogoutModal} handleLogout={handleLogout} isOpen={isOpenLogout} />
        </AppBar>
    );
};

export default Header;
