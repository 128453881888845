import { Typography, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

interface InstanceTableHeaderProps {}

export const InstanceTableHeader: React.FC<InstanceTableHeaderProps> = () => (
    <>
        <TableHead>
            <TableRow>
                <TableCell sx={{ fontSize: "18px" }} align="left">
                    License Status
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }} align="right">
                    <Typography sx={{ fontWeight: 500, mr: "4px", fontSize: "18px" }}>Action</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    </>
);
