import { Avatar, Box, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import { FormModalAvtar, FormModalStyle } from "./style";

interface FormModalProps {
    handleClose: () => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    open: boolean;
    heading: string;
    icon: React.ReactNode;
}

export const FormModal: React.FC<FormModalProps> = ({ handleClose, handleSubmit, open, heading, icon, children }) => (
    <Modal
        sx={{ backdropFilter: "blur(4px)" }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby={heading}
        disableEnforceFocus
    >
        <Paper elevation={4} sx={FormModalStyle}>
            <Avatar sx={FormModalAvtar} component={Paper} elevation={2}>
                {icon}
            </Avatar>
            <div>
                <Typography sx={{ mt: "5px" }} component="h1" variant="h5" gutterBottom>
                    {heading}
                </Typography>
            </div>
            <Box component="form" onSubmit={handleSubmit}>
                {children}
            </Box>
        </Paper>
    </Modal>
);
