import { ConfirmationModal, NumericalPicker } from "@components/Shared";
import { useApiError } from "@lib/provider";
// import { products, getProductFromId } from "@lib/utils";
import { useProducts } from "@hooks";
import { InfoRounded } from "@mui/icons-material";
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { createCheckout } from "..";
import { BlobCanvas } from "./BlobCanvas";
import { CanvasErrorBoundary } from "./CanvasErrorBoundary";

interface PaymentCardProps {}

const useStyles = makeStyles({
    blinking: {
        animation: "$blinkingAnimation 0.5s",
    },
    "@keyframes blinkingAnimation": {
        "0%": {
            opacity: 1,
        },
        "50%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
});

const ProductInfoTooltip = ({ productInfo }: { productInfo: string }) => {
    const [blink, setBlink] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setBlink(true);
        const timeout = setTimeout(() => {
            setBlink(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [productInfo]);

    return (
        <Tooltip title={productInfo} placement="top">
            <InfoRounded
                className={blink ? classes.blinking : ""}
                color="primary"
                sx={{
                    position: "absolute",
                    ml: "92%",
                    mt: "-8%",
                    fontSize: "18px",
                }}
            />
        </Tooltip>
    );
};

export const PaymentCard: React.FC<PaymentCardProps> = () => {
    const { addError } = useApiError();
    const { getSortedProducts, getProductFromId, getDefaultProduct } = useProducts();
    const { query } = useRouter();
    const { mutateAsync, isLoading } = useMutation(createCheckout);
    const [quantity, setquantity] = useState<number>(1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [res, setRes] = useState<boolean>(false);
    const [product, setProduct] = useState(getDefaultProduct());

    const productInfo = `Allows you to use ${product.is_custom ? "upto" : ""} ${
        product.allowed_workers
    } workers, per device`;

    useEffect(() => {
        if (query.qty) {
            setquantity(parseInt(query.qty as string, 10));
        }
        if (query.product_id) {
            setProduct(getProductFromId(query.product_id as string));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setProduct(getDefaultProduct());
    }, [getDefaultProduct]);

    const handleCheckout = async () => {
        try {
            const { message } = await mutateAsync({ quantity, product_id: product.id });

            setRes(true);
            window.location.href = message;
        } catch (error) {
            addError({ error });
        }
    };

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    minWidth: "290px",
                    maxWidth: "290px",
                }}
            >
                <Box sx={{ width: "290px", height: "290px", position: "relative" }}>
                    <CanvasErrorBoundary>
                        <BlobCanvas />
                    </CanvasErrorBoundary>
                </Box>
                <FormControl fullWidth>
                    <ProductInfoTooltip productInfo={productInfo} />
                    <InputLabel id="select-product-label">Product</InputLabel>
                    <Select
                        labelId="select-product-label"
                        id="select-product"
                        value={product.id}
                        label="Product"
                        onChange={(e) => {
                            setProduct(getProductFromId(e.target.value));
                        }}
                    >
                        {getSortedProducts().map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ mx: 1, my: 1, display: "flex", flexDirection: "column" }}>
                    <Box sx={{ my: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        Total Quantity
                        <NumericalPicker min={1} max={999} value={quantity} setValue={setquantity} />
                    </Box>
                    <Button
                        onClick={() => setIsOpen(true)}
                        fullWidth
                        variant="contained"
                        disabled={product.id === "loading"}
                    >
                        {product.id === "loading"
                            ? "Loading Products..."
                            : `Buy for $${(quantity * product.price).toFixed(1)}`}
                    </Button>
                </Box>
            </Paper>
            <ConfirmationModal
                title={`Buy ${quantity} ${product.name} ${quantity > 1 ? "licenses" : "license"} for $${(
                    quantity * product.price
                ).toFixed(1)}?`}
                description={isLoading || res ? "Redirecting..." : "You'll be redirected to the Stripe Checkout page"}
                clickAway
                confirmButtonName="Buy"
                open={isOpen}
                handleCloseModal={() => setIsOpen(false)}
                confirmButton={() => handleCheckout()}
            />
        </>
    );
};
