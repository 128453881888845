import { StyleType } from "@themes";

export const PaymentSectionLayoutStyle: StyleType = {
    flex: 1,
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
};
