import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCart";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import NextLink from "next/link";
import React, { useCallback } from "react";
import { handlePathUpdate } from "@lib/utils";
import { ThemeSwitcherDesktop } from "./ThemeSwitcher";

interface AuthProps {
    pathName: string;
    // router: NextRouter;
    handleLogout: () => void;
}

const PATHVALUES = ["/profile", "/devices", "/payment"];

export const AuthMobile: React.FC<AuthProps & { handleClose: () => void }> = ({
    pathName,
    handleLogout,
    handleClose,
}) => (
    <Box sx={{ width: "100%" }}>
        <List component="nav" aria-label="main mailbox folders">
            <NextLink href="/profile">
                <ListItemButton selected={PATHVALUES.indexOf(pathName) === 0} onClick={handleClose}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </ListItemButton>
            </NextLink>
            <NextLink href="/devices">
                <ListItemButton selected={PATHVALUES.indexOf(pathName) === 1} onClick={handleClose}>
                    <ListItemIcon>
                        <SendToMobileIcon />
                    </ListItemIcon>
                    <ListItemText primary="Devices" />
                </ListItemButton>
            </NextLink>
            <NextLink href="/payment">
                <ListItemButton selected={PATHVALUES.indexOf(pathName) === 2} onClick={handleClose}>
                    <ListItemIcon>
                        <ShoppingCartCheckoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                </ListItemButton>
            </NextLink>
            <ListItemButton
                onClick={() => {
                    handleLogout();
                }}
            >
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    </Box>
);

export const AuthDeskTop: React.FC<AuthProps> = ({ pathName, handleLogout }) => {
    const handlePathChange = useCallback(() => handlePathUpdate(PATHVALUES, pathName), [pathName]);

    return (
        <Tabs
            sx={{
                [`& .${tabsClasses.flexContainer}`]: {
                    "& .MuiTab-labelIcon": { opacity: 1 },
                },
            }}
            indicatorColor={handlePathChange().color}
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs exampl"
            value={handlePathChange().value}
        >
            <NextLink href="/profile">
                <Tab label="Profile" icon={<PersonIcon />} component="span" />
            </NextLink>
            <NextLink href="/devices">
                <Tab label="Devices" icon={<SendToMobileIcon />} component="span" />
            </NextLink>
            <NextLink href="/payment">
                <Tab label="Products" icon={<ShoppingCartCheckoutIcon />} component="span" />
            </NextLink>
            <ThemeSwitcherDesktop />
            <Tab
                label="Log Out"
                onClick={() => {
                    handleLogout();
                }}
                icon={<LogoutIcon />}
                component="span"
            />
        </Tabs>
    );
};
