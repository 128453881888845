/* eslint-disable eqeqeq */
import { InstanceState, InstanceActions, Instance } from "../../types";

export const instanceReducer = (state: InstanceState, action: InstanceActions): InstanceState => {
    switch (action.type) {
        case "fetch":
            return {
                ...state,
                data: action.initialData as Instance[],
                fetched: true,
            };
        case "edit":
            return {
                ...state,
                edited: true,
                data: state.data.map((instance) =>
                    instance.instance_id == action.id ? { ...instance, licensed: !instance.licensed } : instance
                ),
            };
        case "delete":
            return { ...state, data: state.data.filter((instance) => instance.instance_id !== action.id) };
        default:
            return { ...state };
    }
};
