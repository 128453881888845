import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import NextLink from "next/link";
import React, { useCallback } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { handlePathUpdate } from "@lib/utils";
import { ThemeSwitcherDesktop } from "./ThemeSwitcher";

interface NotAuthProps {
    pathName: string;
}

const PATHVALUES = ["/login", "/register"];

export const NotAuthMobile: React.FC<NotAuthProps & { handleClose: () => void }> = ({ pathName, handleClose }) => (
    <Box sx={{ width: "100%" }}>
        <List component="nav" aria-label="main mailbox folders">
            <NextLink href="/login">
                <ListItemButton selected={PATHVALUES.indexOf(pathName) === 0} onClick={handleClose}>
                    <ListItemIcon>
                        <LockOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Login" />
                </ListItemButton>
            </NextLink>
            <NextLink href="/register">
                <ListItemButton selected={PATHVALUES.indexOf(pathName) === 1} onClick={handleClose}>
                    <ListItemIcon>
                        <AppRegistrationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Register" />
                </ListItemButton>
            </NextLink>
        </List>
    </Box>
);

export const NotAuthDeskTop: React.FC<NotAuthProps> = ({ pathName }) => {
    const handlePathChange = useCallback(() => handlePathUpdate(PATHVALUES, pathName), [pathName]);

    return (
        <Tabs
            sx={{
                [`& .${tabsClasses.flexContainer}`]: {
                    "& .MuiTab-labelIcon": { opacity: 1 },
                },
            }}
            indicatorColor={handlePathChange().color}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs exampl"
            value={handlePathChange().value}
        >
            <NextLink href="/login">
                <Tab label="Login" icon={<LockOutlinedIcon />} component="span" />
            </NextLink>
            <NextLink href="/register">
                <Tab label="Register" icon={<AppRegistrationIcon />} component="span" />
            </NextLink>
            <ThemeSwitcherDesktop />
        </Tabs>
    );
};
