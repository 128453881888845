import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Toolbar, Tooltip, Typography, alpha } from "@mui/material";
import React from "react";

interface DeviceTableToolbarProps {
    numSelected: number;
    handleDeleteSelected: () => void;
    handleEditSelected: () => void;
    handleClearSelection: () => void;
    handleOpenManageLicenses: () => void;
    isAllSelected: boolean;
    refreshData: () => void;
    disabled: boolean;
    isLoading: boolean;
}

export const DeviceTableToolbar: React.FC<DeviceTableToolbarProps> = ({
    handleEditSelected,
    handleDeleteSelected,
    numSelected,
    handleClearSelection,
    handleOpenManageLicenses,
    isAllSelected,
    refreshData,
    disabled,
    isLoading,
}) => {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                    Your Devices
                </Typography>
            )}
            {numSelected > 0 ? (
                <Box sx={{ display: "flex", px: "6px" }}>
                    <Tooltip title="Edit">
                        <IconButton sx={{ p: "4px" }} onClick={() => handleEditSelected()}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton sx={{ p: "4px" }} onClick={() => handleDeleteSelected()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Manage Licenses">
                        <IconButton sx={{ p: "4px" }} onClick={() => handleOpenManageLicenses()}>
                            <KeyIcon />
                        </IconButton>
                    </Tooltip>
                    {!isAllSelected && (
                        <Tooltip title="Deselect">
                            <IconButton sx={{ p: "4px" }} onClick={() => handleClearSelection()}>
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            ) : (
                !disabled && (
                    <LoadingButton loading={isLoading} onClick={refreshData}>
                        Refresh
                    </LoadingButton>
                )
            )}
        </Toolbar>
    );
};
