import { Box, Skeleton } from "@mui/material";
import React from "react";
import { ProfileCardContainerStyle, ProfileCardLayoutStyle } from "..";

interface ProfileSkeletonProps {}

export const ProfileSkeleton: React.FC<ProfileSkeletonProps> = () => (
    <Box sx={ProfileCardContainerStyle}>
        <Box sx={ProfileCardLayoutStyle}>
            {/* <DeviceTokenCard token={get_device_token()} /> */}
            <Skeleton variant="circular" width={56} height={56} />
            <Skeleton sx={{ my: 2, width: "90%" }} variant="rectangular" height={150} />
            <Skeleton sx={{ my: 2, width: "90%" }} variant="rectangular" height={150} />
        </Box>
    </Box>
);
