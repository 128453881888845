import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useRouter } from "next/dist/client/router";
import React, { useState } from "react";
import { SubmitHandler, useForm, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { FormButton, FormInputField, PasswordFormInputField } from "@components/Form";
import { useApiError, useProducts } from "@lib/provider";
import { AuthErrorType, register, RegisterInputType } from "..";
import { mapError, validateAndConfirm, validateEmail, validatePassword } from "../utils";
import AuthFormBody from "./AuthFormLayout/AuthFormBody";
import AuthFormContainer from "./AuthFormLayout/AuthFormContainer";
import AuthFormGrid from "./AuthFormLayout/AuthFormGrid";
import AuthFormHeader from "./AuthFormLayout/AuthFormHeader";
import { DeviceAuthTokenModal } from "./DeviceAuthTokenModal";

interface RegisterFormProps {}
export const RegisterForm: React.FC<RegisterFormProps> = React.memo(() => {
    const router = useRouter();
    const { mutateAsync, isLoading } = useMutation(register);
    const {
        register: formRegister,
        formState: { errors: formError },
        handleSubmit,
        setError,
        getValues,
    } = useForm<RegisterInputType>();
    const { addError } = useApiError();

    const [tokenState, setTokenState] = useState<{ token?: string; isOpen: boolean }>({
        token: "",
        isOpen: false,
    });
    const { refetch } = useProducts();
    const handleClose = () => {
        setTokenState({ isOpen: false, token: "" });
    };

    const onSubmit: SubmitHandler<RegisterInputType> = async ({ email, password }) => {
        try {
            const { device_auth_token } = await mutateAsync({ email, password });

            if (device_auth_token !== "*******-***-****-****-************") {
                setTokenState({ isOpen: true, token: device_auth_token });

                return;
            }
            await refetch();
            router.push("/profile");
        } catch (error) {
            const errorMessage = (error as any).response.data.message;

            if (!errorMessage) return;
            const isApi = mapError(errorMessage, setError as UseFormSetError<AuthErrorType>);

            if (isApi) {
                addError({ error });
            }
        }
    };

    return (
        <>
            <AuthFormContainer>
                <AuthFormHeader formTitle="Register">
                    <AppRegistrationIcon />
                </AuthFormHeader>
                <AuthFormBody handleSubmit={handleSubmit(onSubmit)}>
                    <FormInputField
                        label="Email"
                        required
                        register={formRegister("email", {
                            required: "Please provide an email",
                            validate: validateEmail,
                        })}
                        error={Boolean(formError.email?.message)}
                        helperText={formError.email?.message}
                    />
                    <PasswordFormInputField
                        register={formRegister("password", {
                            required: "Please provide a password",
                            validate: validatePassword,
                        })}
                        required
                        label="Password"
                        autoComplete="current-password"
                        error={Boolean(formError.password?.message)}
                        helperText={formError.password?.message}
                    />
                    <PasswordFormInputField
                        register={formRegister("password2", {
                            required: "Please re-enter to confirm password",
                            validate: (value) => validateAndConfirm(value, getValues().password),
                        })}
                        required
                        label="Confirm Password"
                        autoComplete="current-password"
                        error={Boolean(formError.password2?.message)}
                        helperText={formError.password2?.message}
                    />
                    <FormButton text="Register" isLoading={isLoading} />
                    <AuthFormGrid linkto="/login" text="Already have an account?" />
                </AuthFormBody>
            </AuthFormContainer>
            <DeviceAuthTokenModal handleClose={handleClose} state={tokenState} router={router} />
        </>
    );
});
