import { Product } from "@types";
import { DeviceEditConfig } from "..";

export const compareConfigData = (val1: DeviceEditConfig, val2: DeviceEditConfig): boolean => {
    const name = val1.device_name === val2.device_name;
    const bearer = val1.auth_bearer === val2.auth_bearer;
    const ownConfig = val1.uses_own_config === val2.uses_own_config;
    const endpoint = val1.rdm_endpoint === val2.rdm_endpoint;
    const workers = val1.workers === val2.workers;
    const ptc = val1.ptc_auth_url === val2.ptc_auth_url;

    return name && bearer && ownConfig && endpoint && workers && ptc;
};

export const validateWorkers = (product: Product, workers: number) => {
    if (!product.is_custom) return product.allowed_workers;

    if (workers >= product.allowed_workers) return product.allowed_workers;

    return workers;
};
