import { Product } from "@types";
import React from "react";
import { InstancesTableData, InstancesTableSkeleton, NoInstancesUsed } from ".";
import { Instance } from "..";

interface InstanceTableBodyProps {
    instances: Instance[];
    handleDeleteInstance: (instance: Instance) => void;
    handleEditInstance: (instance: Instance) => void;
    isLoading: boolean;
    product: Product;
}

export const InstanceTableBody: React.FC<InstanceTableBodyProps> = ({
    instances,
    handleDeleteInstance,
    handleEditInstance,
    isLoading,
    product,
}) => {
    if (isLoading) {
        return (
            <>
                <InstancesTableSkeleton />
            </>
        );
    }

    if (!isLoading && instances.length === 0) {
        return <NoInstancesUsed />;
    }

    if (!instances.length) return null;

    return (
        <>
            {instances.map((instance) => (
                <InstancesTableData
                    handleDeleteInstance={handleDeleteInstance}
                    handleEditInstance={handleEditInstance}
                    key={instance.instance_id}
                    product={product}
                    {...instance}
                />
            ))}
        </>
    );
};
