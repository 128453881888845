/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useState } from "react";
import { NotificationContextType, NotificationStateType } from "..";

interface NotificationProviderProps {}

export const NotificationContext = createContext<NotificationContextType>({
    notification: { message: "", isOpen: false, type: "success" },
    addNotification: () => {},
    removeNotification: () => {},
});

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [notification, setNotification] = useState<NotificationStateType>({
        message: "",
        isOpen: false,
        type: "success",
    });

    const removeNotification = useCallback(() => {
        setNotification({ ...notification, isOpen: false });
    }, [notification]);

    const addNotification = ({ message, type, isOpen }: NotificationStateType) => {
        setNotification({ message, isOpen, type });
    };
    const contextValue: NotificationContextType = {
        notification,
        addNotification: useCallback(({ message, type }) => {
            addNotification({ message, isOpen: true, type });
        }, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        removeNotification: useCallback(() => removeNotification(), [removeNotification]),
    };

    return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};
