import { StyleType } from "@themes";

export const AuthFormContainerStyle: StyleType = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "space-evenly",
    justifyContent: "center",
    position: "relative",
};

export const linkStyle: StyleType = {
    color: "text.secondary",
    cursor: "pointer",
    ":hover": { textDecoration: "underline text.secondary" },
};

export const AuthFormContainerPaperStyle: StyleType = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "25px 25px",
    position: "relative",
};

export const AuthFormHeaderAvtar: StyleType = {
    bgcolor: "secondary.main",
    position: "absolute",
    top: "-25px",
};

export const authLayoutStyle = {
    flex: 1,
    display: "flex",
    alignContent: "space-around",
};
