import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React, { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type InputFieldProps = TextFieldProps & {
    register: UseFormRegisterReturn;
};

export const FormInputField: React.FC<InputFieldProps> = ({ register: { name, onBlur, onChange, ref }, ...props }) => (
    <TextField
        margin="normal"
        required
        fullWidth
        variant="filled"
        name={name}
        id={name}
        type={name}
        inputRef={ref}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
        autoComplete="off"
    />
);
export const PasswordFormInputField: React.FC<InputFieldProps> = ({
    register: { name, onBlur, onChange, ref },
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const inputProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    sx={{
                        color: showPassword ? "text.secondary" : "default",
                    }}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        ),
    };

    return (
        <TextField
            margin="normal"
            required
            fullWidth
            variant="filled"
            name={name}
            id={name}
            inputRef={ref}
            onChange={onChange}
            onBlur={onBlur}
            type={showPassword ? "text" : "password"}
            {...props}
            InputProps={inputProps}
        />
    );
};
