import { validatePassword } from "@features/auth";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const changePasswordValidator = (values: {
    old_password: string;
    new_password: string;
    new_password2: string;
}) => {
    const errors: any = {};

    if (!values.old_password || values.old_password.length < 8) {
        errors.old_password = "Password too short";
    } else if (!values.new_password || values.new_password.length < 8) {
        errors.new_password = "Password too short";
    } else if (!values.new_password2 || values.new_password !== values.new_password2) {
        errors.new_password2 = "Please make sure your passwords match";
    } else if (values.new_password === values.old_password) {
        errors.new_password = "New Password can't be same as your old password";
    }

    return errors;
};

export const validateAndConfirmEquals = (value: string, passVal: string, equal: boolean): string | undefined => {
    const invalidPassword = validatePassword(value);

    if (invalidPassword) {
        return invalidPassword;
    }
    if ((value === passVal) !== equal) {
        return equal ? "Please make sure your passwords match" : "New Password cannot be same as Old Password";
    }

    return undefined;
};
