import { Box, FormControl, InputLabel, MenuItem, Pagination, Select, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface TablePaginatorProps {
    pages: number;
    handleChangeRow: (newRow: number) => void;
    row: number;
    page: number;
    handleChangePage: (newPage: number) => void;
    devices: number;
}

export const TablePaginator: React.FC<TablePaginatorProps> = ({
    pages,
    handleChangeRow,
    row,
    page,
    handleChangePage,
    devices,
}) => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down("md"));

    const handlePageChange = (_: React.ChangeEvent<unknown>, pageNum: number) => {
        if (pageNum === page) return;
        handleChangePage(pageNum);
    };

    const handleRowChange = (rows: number) => {
        let newPage = 0;

        if (page === 1) {
            newPage = 1;
        } else if (page === Math.ceil(pages / row)) {
            newPage = Math.ceil(pages / rows);
        } else {
            newPage = Math.ceil((page / Math.ceil(pages / row)) * Math.ceil(pages / rows));
        }
        handleChangeRow(rows);
        handleChangePage(newPage);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Pagination
                onChange={handlePageChange}
                count={Math.ceil(pages / row)}
                size="large"
                color="primary"
                siblingCount={isMobile ? 0 : 2}
                page={page}
                shape="rounded"
                variant="text"
            />
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <span>{`${(page - 1) * row + devices}/${pages}`}</span>
                <Box sx={{ width: "26px" }} />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
                    <InputLabel variant="standard" id="Rows selector">
                        Rows
                    </InputLabel>
                    <Select
                        onChange={(rows) => handleRowChange(rows.target.value as number)}
                        labelId="Rows selector"
                        id="Rows selector"
                        value={row}
                        label="Age"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={999}>All</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};
