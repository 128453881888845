/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const validateEmail = (value: string): string | undefined => {
    // eslint-disable-next-line prettier/prettier
    if (!(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i).test(value)) {
        return "Invalid email address";
    }

    return undefined;
};

export const validatePassword = (value: string): string | undefined => {
    if (value.length < 8) {
        return "Password must have atleast 8 chracters";
    }

    return undefined;
};

export const validateAndConfirm = (value: string, passValue: string): string | undefined => {
    const invalidPassword = validatePassword(value);

    if (invalidPassword) {
        return invalidPassword;
    }

    if (passValue !== value) {
        return "Please make sure your passwords match";
    }

    return undefined;
};
