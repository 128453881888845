import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { NextRouter } from "next/dist/client/router";
import React from "react";
import { ConfirmationModalStyle } from "@components/Shared/style";
import { useNotification } from "@lib/provider";
import { copyToClipboard } from "@lib/utils";

interface DeviceAuthTokenModalProps {
    state: { token?: string; isOpen: boolean };
    router: NextRouter;
    handleClose: () => void;
}

export const DeviceAuthTokenModal: React.FC<DeviceAuthTokenModalProps> = ({
    state: { isOpen, token },
    handleClose,
    router,
}) => {
    const { addNotification } = useNotification();

    return (
        <>
            <Modal
                sx={{ backdropFilter: "blur(4px)" }}
                open={isOpen}
                aria-labelledby="confirmation-modal-title"
                aria-describedby="confirmation-modal-description"
            >
                <Box sx={ConfirmationModalStyle}>
                    <h3 id="confirmation-modal-title">Device Auth Token</h3>
                    Thank you for regestering! This is your device auth token that you can use to connect your android
                    devices to Atlas.
                    <br />
                    <br />
                    <strong>You won't be able to see this token again!</strong>
                    <pre>{token}</pre>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            disabled={!token}
                            onClick={() => {
                                if (!token) return;
                                copyToClipboard(token);
                                addNotification({ message: "Copied device auth token", type: "success" });
                            }}
                        >
                            Copy
                        </Button>
                        <Button
                            onClick={() => {
                                handleClose();
                                router.push("/profile");
                            }}
                        >
                            Done
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
