/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useCallback, createContext } from "react";
import { ServerErrorResponse } from "@types";
import { isProd } from "@lib/utils";
import { APIErrorContextType, APIErrorStateType } from "..";

interface APIErrorProviderProps {}

const CODES_TO_IGNORE = [401, 500];

export const APIErrorContext = createContext<APIErrorContextType>({
    error: { message: "", isError: false },
    addError: () => {},
    removeError: () => {},
});

export const APIErrorProvider: React.FC<APIErrorProviderProps> = ({ children }) => {
    const [error, setError] = useState<APIErrorStateType>({ message: "", isError: false });

    const removeError = () => setError({ message: "", isError: false });

    const addError = ({ message, isError }: APIErrorStateType) => setError({ message, isError });

    const contextValue: APIErrorContextType = {
        error,
        addError: useCallback(({ error: apiError }) => {
            // eslint-disable-next-line no-underscore-dangle
            const _apiError = apiError as any;

            if (!_apiError.response) {
                // eslint-disable-next-line no-unused-expressions
                if (!isProd) {
                    addError({ message: _apiError.message as string, isError: true });
                    // eslint-disable-next-line no-console
                    console.log(_apiError);
                }

                return;
            }
            const { status, message } = _apiError.response.data as ServerErrorResponse;

            if (CODES_TO_IGNORE.includes(status)) return;
            addError({ message, isError: true });
        }, []),
        removeError: useCallback(() => removeError(), []),
    };

    return <APIErrorContext.Provider value={contextValue}>{children}</APIErrorContext.Provider>;
};
