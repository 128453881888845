import { getAllProducts } from "@lib/utils";
import { Product } from "@types";
import React, { createContext } from "react";
import { useQuery } from "react-query";

export const defaultValue = {
    products: [
        {
            id: "loading",
            name: "Loading",
            allowed_workers: 0,
            price: 1,
            is_default: false,
            is_custom: false,
            is_individual: false,
        },
    ],
    refetch: async () => {},
    isLoading: true,
};

export const ProductsContext = createContext<{
    products: Product[];
    isLoading: boolean;
    // eslint-disable-next-line prettier/prettier, func-call-spacing
    refetch:() => Promise<any>;
}>(defaultValue);

export const ProductProvider: React.FC = ({ children }) => {
    const { data, refetch, isLoading, isError } = useQuery<{ products: Product[] }>("/products", getAllProducts, {
        cacheTime: Infinity,
        staleTime: Infinity,
        retry: (count, error) => {
            if (count > 2) return false;
            if ((error as any)?.response?.status === 401) return false;

            return true;
        },
    });

    return (
        <ProductsContext.Provider
            value={{ products: data?.products ?? defaultValue.products, refetch, isLoading: isLoading || isError }}
        >
            {children}
        </ProductsContext.Provider>
    );
};
