import { FormInputField, FormModal, ModalFormButtons } from "@components/Form";
import { useApiError, useColorMode, useNotification } from "@lib/provider";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { batchMethodUpdateInfo } from "../api";

interface DeviceBatchInfoEditFormProps {
    isOpen: boolean;
    handleClose: () => void;
    device_ids: number[];
    handleSubmit: (device_prefix: string) => void;
}

export const DeviceBatchInfoEditFormModal: React.FC<DeviceBatchInfoEditFormProps> = ({
    device_ids,
    handleClose,
    handleSubmit,
    isOpen,
}) => {
    const {
        register,
        handleSubmit: handleSubmitForm,
        reset,
        formState: { errors: formErrors },
    } = useForm<{ device_prefix: string; endpoint: string; auth_bearer: string; ptc_auth_url: string }>();

    const closeAndResetModal = () => {
        handleClose();
        reset({
            device_prefix: "",
            endpoint: "",
            auth_bearer: "",
            ptc_auth_url: "",
        });
    };
    const { mutateAsync, isLoading } = useMutation(batchMethodUpdateInfo);

    const { addError } = useApiError();
    const { addNotification } = useNotification();

    const { product } = useColorMode();

    return (
        <>
            <FormModal
                heading={`Edit ${device_ids.length} Device(s) Info`}
                open={isOpen}
                handleClose={closeAndResetModal}
                handleSubmit={handleSubmitForm(async (data) => {
                    try {
                        const { affected_device_ids } = await mutateAsync({ device_ids, ...data });

                        addNotification({
                            message: `Updated info for ${affected_device_ids.length} device(s)`,
                            type: "success",
                        });

                        if (data.device_prefix) {
                            handleSubmit(data.device_prefix);
                        }
                        closeAndResetModal();
                    } catch (error) {
                        const errorMessage = (error as any).response?.data.message;

                        if (!errorMessage) return;

                        addError({
                            error: errorMessage,
                        });
                    }
                })}
                icon={<EditIcon />}
            >
                <FormInputField
                    register={register("device_prefix")}
                    label="Add Name Prefix"
                    required={false}
                    error={Boolean(formErrors.device_prefix?.message)}
                    helperText={formErrors.device_prefix?.message}
                />
                <FormInputField
                    register={register("endpoint")}
                    label={`${product === "atlas" ? "RDM" : "Rotom"} Url`}
                    required={false}
                    error={Boolean(formErrors.endpoint?.message)}
                    helperText={formErrors.endpoint?.message}
                />
                <FormInputField
                    register={register("auth_bearer")}
                    label="Auth Bearer"
                    required={false}
                    error={Boolean(formErrors.auth_bearer?.message)}
                    helperText={formErrors.auth_bearer?.message}
                />
                {product === "atlas" && (
                    <FormInputField
                        register={register("ptc_auth_url")}
                        label="Ptc Auth Url"
                        required={false}
                        error={Boolean(formErrors.ptc_auth_url?.message)}
                        helperText={formErrors.ptc_auth_url?.message}
                    />
                )}
                <ModalFormButtons closeFn={closeAndResetModal} text="Submit" isLoading={isLoading} />
            </FormModal>
        </>
    );
};
