import React, { useState } from "react";
import { alpha, IconButton, InputAdornment, InputBase, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export interface SearchBarProps {
    handleChange: (data: string) => void;
    handleClear: () => void;
    handleSearchOnEnterClick: (data: string) => void;
}

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // width: "100%",
    // maxWidth: 360,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // marginLeft: theme.spacing(2),
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        // [theme.breakpoints.up("md")]: {
        //     width: "20ch",
        // },
    },
    width: "100%",
}));

export const SearchBar: React.FC<SearchBarProps> = React.memo(
    ({ handleChange, handleClear, handleSearchOnEnterClick }) => {
        const [search, setSearch] = useState<string>("");

        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
            handleChange(e.target.value);
        };

        const handleOnSearchClear = () => {
            setSearch("");
            handleClear();
        };

        return (
            <>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        // onKeyDown={(e) => {
                        //     if (e. === "") {

                        //     }
                        // }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSearchOnEnterClick(search);
                            }
                        }}
                        onChange={handleOnChange}
                        placeholder="Search…"
                        endAdornment={
                            <InputAdornment sx={{ px: 2 }} position="end">
                                <IconButton
                                    aria-label={search ? "clear search" : ""}
                                    onClick={() => handleOnSearchClear()}
                                    edge="end"
                                >
                                    {search ? <ClearIcon /> : null}
                                </IconButton>
                            </InputAdornment>
                        }
                        value={search || ""}
                        inputProps={{ "aria-label": "search" }}
                    />
                </Search>
            </>
        );
    }
);
