/* eslint-disable no-nested-ternary */
import { Device, DeviceState, DeviceWorkerChangeModel, LicenseStatus } from "../types";

export const updateOnIndividualEdit = (devices: Device[], device_id: number, device_name: string): Device[] =>
    devices.map((device) => (device_id === device.id ? { ...device, device_name } : device));

export const updateOnBatchEdit = (state: DeviceState, device_name: string): Device[] =>
    state.devices.map((device) =>
        state.selected_devicesIds.includes(device.id) ? { ...device, device_name } : device
    );

export const updateOnBatchInfoEdit = (state: DeviceState, device_prefix: string): Device[] =>
    state.devices.map((device) =>
        state.selected_devicesIds.includes(device.id)
            ? { ...device, device_name: device_prefix + device.device_name }
            : device
    );

export const updateOnLicenseChange = (
    devices: Device[],
    updaded_device_ids: number[],
    status: LicenseStatus,
    product_id: string
): Device[] =>
    devices.map((device) =>
        updaded_device_ids.includes(device.id) ? { ...device, license_status: status, product_id } : device
    );

export const updateOnCustomLicenseChange = (
    devices: Device[],
    changes: DeviceWorkerChangeModel[],
    product_id: string
): Device[] => {
    return devices.map((device) => {
        const myDevice = changes.find((d) => d.device_id === device.id);

        if (!myDevice) return device;

        let status = LicenseStatus.PARTIAL;

        if (myDevice.active_instances === 0) {
            status = LicenseStatus.INACTIVE;
        }
        if (myDevice.active_instances === myDevice.total_instances) {
            status = LicenseStatus.ACTIVE;
        }

        return { ...device, license_status: status, product_id: status === LicenseStatus.INACTIVE ? "" : product_id };
    });
};
