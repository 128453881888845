import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { setEmail } from "@lib/utils";
import {
    ProfileAvatarStyle,
    ProfileCardContainerStyle,
    ProfileCardHeaderStyle,
    ProfileCardLayoutStyle,
} from "../style";
import { UserDataResponse } from "../types";
import { EmailCard } from "./EmailCard";
import { InstancesCard } from "./InstancesCard";

interface ProfileCardProps {
    user: UserDataResponse;
    refreshData: () => void;
    isLoadingProfile: boolean;
}

export const ProfileCard: React.FC<ProfileCardProps> = ({ user, refreshData, isLoadingProfile }) => {
    useEffect(() => {
        setEmail(user.email);
    });

    return (
        <Box sx={ProfileCardContainerStyle}>
            <Box sx={ProfileCardLayoutStyle}>
                <Box sx={ProfileCardHeaderStyle}>
                    <Avatar sx={ProfileAvatarStyle}>{user.email[0].toUpperCase()}</Avatar>
                    <Typography variant="h5" component="div">
                        Your Profile
                    </Typography>
                </Box>
                <EmailCard user={user.email} />
                <InstancesCard isLoadingProfile={isLoadingProfile} refreshData={refreshData} userInfo={user} />
            </Box>
        </Box>
    );
};

export default ProfileCard;
