import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NextRouter } from "next/dist/client/router";
import React from "react";
import { AuthDeskTop } from "./AuthHeader";
import { NotAuthDeskTop } from "./NotAuthHeader";

interface HeaderNavProps {
    handleMobileMenuOpen: () => void;
    router: NextRouter;
    isDrawerOpen: boolean;
    user: boolean;
    handleLogout: () => void;
}
export const HeaderNav: React.FC<HeaderNavProps> = ({
    handleMobileMenuOpen,
    router,
    isDrawerOpen,
    user,
    handleLogout,
}) => (
    <Toolbar>
        <IconButton
            size="large"
            color="inherit"
            onClick={handleMobileMenuOpen}
            sx={{ display: { md: "none", xs: "flex" } }}
        >
            {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Box sx={{ display: { md: "flex", sm: "none", xs: "none" } }}>
            {user ? (
                <AuthDeskTop handleLogout={handleLogout} pathName={router.pathname} />
            ) : (
                <NotAuthDeskTop pathName={router.pathname} />
            )}
        </Box>
    </Toolbar>
);
