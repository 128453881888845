import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { ColorType } from "@types";
import { ConfirmationModalStyle } from "./style";

interface ConfirmationModalProps {
    open: boolean;
    confirmButton: () => void;
    handleCloseModal: () => void;
    title: string;
    description?: string | React.ReactNode;
    confirmButtonName?: string;
    confirmButtonVariant?: "text" | "outlined" | "contained";
    cancleButtonVariant?: "text" | "outlined" | "contained";
    cancleButtonName?: string;
    clickAway?: boolean;
    disableButton?: boolean;
    confirmBtnColor?: ColorType;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    confirmButton,
    handleCloseModal,
    description,
    open,
    title,
    clickAway,
    confirmButtonName = "Confirm",
    cancleButtonName = "Close",
    disableButton,
    confirmBtnColor,
    confirmButtonVariant,
    cancleButtonVariant,
}) => {
    const handleClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === "backdropClick" && clickAway) {
            return;
        }

        handleCloseModal();
    };

    return (
        <Modal
            sx={{ backdropFilter: "blur(4px)" }}
            open={open}
            onClose={handleClose}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
        >
            <Box sx={ConfirmationModalStyle}>
                <h3 id="confirmation-modal-title">{title}</h3>
                {description && <p id="confirmation-modal-description">{description}</p>}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant={cancleButtonVariant} onClick={() => handleCloseModal()}>
                        {cancleButtonName}
                    </Button>
                    <Button
                        variant={confirmButtonVariant}
                        color={confirmBtnColor || "primary"}
                        disabled={disableButton}
                        onClick={() => confirmButton()}
                        sx={{ mr: "8px" }}
                    >
                        {confirmButtonName}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
