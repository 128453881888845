import { AxiosResponse } from "axios";
import axiosInstance from "@lib/axios";
import { DeviceInfoResponseType } from "../types";

export const getInfo = async (device_id: number): Promise<DeviceInfoResponseType> => {
    const { data }: AxiosResponse<DeviceInfoResponseType> = await axiosInstance.get("/device/info", {
        params: { device_id },
    });

    return data;
};
