/* eslint-disable eqeqeq */
import { ModalActions, ModalState } from "../../types";

export const modalReducer = (state: ModalState, action: ModalActions): ModalState => {
    switch (action.type) {
        case "EDIT":
            return {
                ...state,
                edit: true,
            };
        case "DELETE":
            return {
                ...state,
                delete: true,
            };
        case "DELETE_DEVICE":
            return {
                ...state,
                delete_device: true,
            };
        case "RESET_EDIT":
            return {
                ...state,
                edit: false,
            };
        case "RESET_DELETE":
            return {
                ...state,
                delete: false,
            };
        case "RESET_DELETE_DEVICE":
            return {
                ...state,
                delete_device: false,
            };

        default:
            return { ...state };
    }
};
