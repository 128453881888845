import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import React, { useState } from "react";

interface DialogWithInputProps {
    open: boolean;
    handleClose(): void;
    onSubmit(amount: number): void;
}

export const DialogWithInput: React.FC<DialogWithInputProps> = ({ handleClose, open, onSubmit }) => {
    const [amount, setAmount] = useState(1);

    return (
        <Dialog maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle>Activation amount</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the number of licenses you'd like to activate, for each device
                </DialogContentText>
                <TextField
                    required
                    margin="dense"
                    id="amount"
                    name="amount"
                    label="Activation Amount"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                        // eslint-disable-next-line no-extra-boolean-cast
                        const input = parseInt(e.target.value, 10);

                        setAmount(input);
                    }}
                    value={amount}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={() => {
                        onSubmit(amount);
                    }}
                >
                    Activate
                </Button>
            </DialogActions>
        </Dialog>
    );
};
