import { StyleType } from "@themes";

export const instanceContainerStyle: StyleType = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    // position: "relative",
    justifyContent: "flex-start",
};

export const instancetLayoutStyle: StyleType = {
    flex: 1,
    display: "flex",
    // position: "relative",
    alignItems: "stretch",
};

export const instanceToolbarStyle: StyleType = {
    pl: 0,
};

export const instanceTableContainerStyle: StyleType = {
    minWidth: "100%",
    maxWidth: "100%",
    mx: "auto",
    mt: "10px",
};

export const flexColumn: StyleType = {
    display: "flex",
    flexDirection: "column",
};

export const ConfigFormContainerStyle: StyleType = {
    ...flexColumn,
};

export const SpinnerCrownContainer: StyleType = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "96px",
    height: "96px",
    overflow: "visible",
    zIndex: 20001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
