import { Typography, Button, Box } from "@mui/material";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useState } from "react";
import { AnimatedCashBlobComponent } from ".";
import { ButtonComponent, TypographyComponent } from "../style/index";

interface FaliureComponentProps {}

export const FaliureComponent: React.FC<FaliureComponentProps> = () => {
    const [animationEnd, setAnimationEnd] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimationEnd(true);
        }, 3500);

        return () => {
            setAnimationEnd(false);
            clearTimeout(timeout);
        };
    }, []);

    const { push } = useRouter();

    return animationEnd ? (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography sx={{ ...TypographyComponent }} variant="h4">
                    Purchase Failed
                </Typography>
                <Button
                    onClick={() => {
                        push("/payment");
                    }}
                    variant="contained"
                    sx={{ m: 2, ...ButtonComponent }}
                >
                    RETRY PAYMENT
                </Button>
            </Box>
        </>
    ) : (
        <AnimatedCashBlobComponent />
    );
};
