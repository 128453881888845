import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { DeviceTokenModalType } from "..";
import { resetDeviceToken } from "../api/resetDeviceToken";
// import { ChangeEmailModal } from "./ChangeEmailModal";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { DeviceAuthToken } from "./DeviceAuthToken";

interface EmailCardProps {
    user: string;
}

export const EmailCard: React.FC<EmailCardProps> = ({ user }) => {
    const [isChangePassModal, setIsChangePassModal] = useState<boolean>(false);
    const [tokenState, setTokenState] = useState<DeviceTokenModalType>({
        token: "",
        isOpenAuthModal: false,
        isOpenWarningModal: false,
    });

    const { refetch } = useQuery("deviceToken", resetDeviceToken, {
        enabled: false,
    });

    const fetchToken = async () => {
        setTokenState({ isOpenWarningModal: false, isOpenAuthModal: true });
        const { data } = await refetch();

        setTokenState({ isOpenWarningModal: false, isOpenAuthModal: true, token: data?.device_auth_token });
    };

    const handleCloseTokenModal = () => {
        setTokenState({ isOpenWarningModal: false, isOpenAuthModal: false, token: "" });
    };
    const handleCloseWarningModal = () => {
        setTokenState({ ...tokenState, isOpenWarningModal: false });
    };
    const handleOpenWarningModal = () => {
        setTokenState({ ...tokenState, isOpenWarningModal: true });
    };

    return (
        <>
            <Card sx={{ width: "90%", mb: "25px" }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Email
                    </Typography>
                    <Typography color="text.secondary">{user}</Typography>
                </CardContent>
                <Grid container>
                    <Grid sx={{ m: "6px" }} item>
                        <Button onClick={() => setIsChangePassModal(true)} variant="text">
                            Change Password
                        </Button>
                    </Grid>
                    <Grid sx={{ m: "6px" }} item>
                        <Button onClick={() => handleOpenWarningModal()} variant="text">
                            Reset Device Auth Token
                        </Button>
                    </Grid>
                </Grid>
            </Card>
            {/* <ChangeEmailModal handleClose={() => setIsChangeEmailModal(false)} isOpen={isChangeEmailModal} /> */}
            <ChangePasswordModal handleClose={() => setIsChangePassModal(false)} isOpen={isChangePassModal} />
            <DeviceAuthToken
                handleCloseWarningModal={handleCloseWarningModal}
                fetchToken={fetchToken}
                state={tokenState}
                handleCloseTokenModal={handleCloseTokenModal}
            />
        </>
    );
};
