import { Checkbox, TableCell, TableHead, TableRow, Typography, TableSortLabel } from "@mui/material";
import React from "react";
import { SortType } from "../types";
import { getSortDirection } from "../utils";

interface DeviceTableHeaderProps {
    handleSelectAll: () => void;
    isSelection: boolean;
    disableSelection: boolean;
    isSmallScreen: boolean;
    sortState: SortType;
    handleSort: (label: string) => void;
}

export const DeviceTableHeader: React.FC<DeviceTableHeaderProps> = ({
    isSelection,
    handleSelectAll,
    disableSelection,
    isSmallScreen,
    sortState,
    handleSort,
}) => (
    <>
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={disableSelection}
                        color="primary"
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={isSelection}
                        onChange={handleSelectAll}
                        inputProps={{
                            "aria-label": "select all devices",
                        }}
                    />
                </TableCell>
                <TableCell sx={{ fontSize: "18px", px: "8px" }} align="left">
                    <TableSortLabel
                        active={sortState.includes("NAME")}
                        direction={getSortDirection(sortState)}
                        onClick={() => handleSort("name")}
                    >
                        Device Name
                    </TableSortLabel>
                </TableCell>
                {!isSmallScreen && (
                    <TableCell sx={{ fontSize: "18px", px: "8px" }} align="left">
                        <TableSortLabel
                            active={sortState.includes("LISC")}
                            direction={getSortDirection(sortState)}
                            onClick={() => handleSort("lisc")}
                        >
                            License
                        </TableSortLabel>
                    </TableCell>
                )}
                <TableCell sx={{ fontSize: "18px", pl: "8px", pr: "16px" }} align="right">
                    <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>Actions</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    </>
);
