import { SearchBar, SearchBarProps } from "@components/Shared";
import { Box, Button, Table, TableContainer } from "@mui/material";
import React from "react";
import { DeviceState } from "..";
import { DeviceTableToolbar } from "./DeviceTableToolbar";
import { TablePaginator } from "./TablePaginator";
import { useRouter } from "next/router";

interface DeviceTableContainerProps extends SearchBarProps {
    size: number;
    handleDeleteSelected: () => void;
    handleEditSelected: () => void;
    state: DeviceState;
    handleClearSelection: () => void;
    handleOpenManageLicenses: () => void;
    isAllSelected: boolean;
    row: number;
    handleChangeRow: (newRow: number) => void;
    page: number;
    handleChangePage: (newPage: number) => void;
    handleSearchOnEnterClick: (data: string) => void;
    refetchDevices: () => void;
    isLoading: boolean;
}

export const DeviceTableContainer: React.FC<DeviceTableContainerProps> = ({
    size,
    children,
    handleEditSelected,
    handleDeleteSelected,
    handleChange,
    handleClear,
    state: { total, devices },
    handleClearSelection,
    handleOpenManageLicenses,
    isAllSelected,
    row,
    handleChangeRow,
    page,
    handleChangePage,
    handleSearchOnEnterClick,
    refetchDevices,
    isLoading,
}) => {
    const height = row <= 10 ? { maxHeight: "60vh" } : {};
    const router = useRouter();

    return (
        <>
            <Box sx={{ width: "100%", minHeight: "100%" }}>
                <SearchBar
                    handleSearchOnEnterClick={handleSearchOnEnterClick}
                    handleChange={handleChange}
                    handleClear={handleClear}
                />
                <Button
                    style={{
                        width: "100%",
                    }}
                    variant="contained"
                    onClick={() => {
                        router.push("/payment");
                    }}
                >
                    Get more licenses
                </Button>
                <DeviceTableToolbar
                    disabled={!devices.length}
                    isAllSelected={isAllSelected}
                    handleEditSelected={handleEditSelected}
                    handleDeleteSelected={handleDeleteSelected}
                    numSelected={size}
                    handleClearSelection={handleClearSelection}
                    handleOpenManageLicenses={handleOpenManageLicenses}
                    refreshData={refetchDevices}
                    isLoading={isLoading}
                />
                <TableContainer
                    sx={{
                        "&::-webkit-scrollbar": {
                            width: "2px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            visibility: "hidden",
                        },
                        ":hover": {
                            "&::-webkit-scrollbar-thumb": {
                                visibility: "visible",
                            },
                        },
                        ...height,
                    }}
                >
                    <Table size="medium" stickyHeader aria-label="your devices">
                        {children}
                    </Table>
                </TableContainer>
                <TablePaginator
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRow={handleChangeRow}
                    row={row}
                    pages={total}
                    devices={devices.length}
                />
            </Box>
        </>
    );
};
