import { Container } from "@mui/material";
import Head from "next/head";
import React, { useCallback } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useRouter } from "next/router";
import { deviceLayoutStyle } from "@features/devices";
import { instancetLayoutStyle } from "@features/instance";
import { PaymentSectionLayoutStyle } from "@features/payments";
import { ProfileLayoutStyle } from "@features/profile";
import { authLayoutStyle } from "@features/auth";
import { StyleType } from "@themes";
import { CheckoutLayoutContainer } from "@features/checkout";
import { useColorMode } from "@lib/provider";
import { toTitleCase } from "@lib/utils";

export const layoutProps: Record<
    string,
    {
        pageName: string;
        layoutStyle: StyleType;
        type: string;
        hasQuery?: boolean;
    }
> = {
    "/devices": { pageName: "Devices", layoutStyle: deviceLayoutStyle, type: "sm" },
    "/profile": { pageName: "Profile", type: "sm", layoutStyle: ProfileLayoutStyle },
    "/payment": { type: "md", pageName: "Payment", layoutStyle: PaymentSectionLayoutStyle },
    "/device/[id]": { pageName: "Device", type: "md", layoutStyle: instancetLayoutStyle, hasQuery: true },
    "/login": { pageName: "Login", layoutStyle: authLayoutStyle, type: "xs" },
    "/register": { pageName: "Register", layoutStyle: authLayoutStyle, type: "xs" },
    "/checkout": { type: "md", pageName: "Checkout", layoutStyle: CheckoutLayoutContainer },
};

interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { route, query } = useRouter();
    const { product } = useColorMode();

    const getRoutProps = useCallback(() => {
        const params = { ...layoutProps[route] };

        if (params?.hasQuery) {
            params.pageName = `${params.pageName}-${query.id}`;
        }

        return params;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    return (
        <>
            <Head>
                <title>{`${toTitleCase(product)}${
                    getRoutProps().pageName ? ` | ${getRoutProps().pageName}` : ""
                }`}</title>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <div className="main-layout">
                <Header />
                <Container component="main" maxWidth={getRoutProps().type as any} sx={getRoutProps().layoutStyle}>
                    {children}
                </Container>
                <Footer />
            </div>
        </>
    );
};
