import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SelectButton, { SelectButtonProps } from "./SelectButton";
import { ConfirmationModalStyle } from "./style";

interface ConfirmationModalWithSelectButtonProps extends SelectButtonProps {
    open: boolean;
    handleCloseModal: () => void;
    title: string;
    description?: string | React.ReactNode;
    cancleButtonVariant?: "text" | "outlined" | "contained";
    cancleButtonName?: string;
    clickAway?: boolean;
    disableButton?: boolean;
}

export const ConfirmationModalWithSelectButton: React.FC<ConfirmationModalWithSelectButtonProps> = ({
    handleCloseModal,
    description,
    open,
    title,
    clickAway,
    cancleButtonName = "Close",
    cancleButtonVariant,
    ...selectButtonProps
}) => {
    const handleClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === "backdropClick" && clickAway) {
            return;
        }
        handleCloseModal();
    };

    return (
        <Modal
            sx={{ backdropFilter: "blur(4px)" }}
            open={open}
            onClose={handleClose}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
        >
            <Box sx={ConfirmationModalStyle}>
                <h3 id="confirmation-modal-title">{title}</h3>
                {description && <p id="confirmation-modal-description">{description}</p>}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant={cancleButtonVariant} onClick={() => handleCloseModal()}>
                        {cancleButtonName}
                    </Button>
                    <SelectButton {...selectButtonProps} />
                </Box>
            </Box>
        </Modal>
    );
};
