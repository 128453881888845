import { Grid, Typography } from "@mui/material";
import NextLink from "next/link";
import React from "react";
import { linkStyle } from "../../style";

interface AuthFormGridProps {
    linkto: string;
    text: string;
}

const AuthFormGrid: React.FC<AuthFormGridProps> = ({ linkto, text }) => (
    <>
        <Grid container>
            <Grid item>
                <NextLink href={linkto}>
                    <Typography color="secondary" sx={linkStyle} component="p" variant="body2">
                        {text}
                    </Typography>
                </NextLink>
            </Grid>
        </Grid>
    </>
);

export default AuthFormGrid;
