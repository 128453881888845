import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { FormInputField, FormModal, ModalFormButtons } from "@components/Form";
import { batchMethodUpdate } from "../api";
import { Device } from "../types";

interface DeviceEditFormModalProps {
    isOpen: boolean;
    handleClose: () => void;
    device: Device;
    handleSubmit: (device_name: string) => void;
}

export const DeviceEditFormModal: React.FC<DeviceEditFormModalProps> = ({
    handleClose,
    handleSubmit,
    isOpen,
    device,
}) => {
    const { mutateAsync, isLoading } = useMutation(batchMethodUpdate);
    const {
        register,
        setError,
        handleSubmit: handleSubmitForm,
        formState: { errors: formErrors },
        setValue,
    } = useForm<{ device_name: string }>();

    useEffect(() => {
        setValue("device_name", device.device_name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device]);

    const onSubmit: SubmitHandler<{ device_name: string }> = async ({ device_name }) => {
        if (device_name === device.device_name) {
            setError("device_name", {
                type: "manual",
                message: "Unchanged name, change name to submit",
            });

            return;
        }
        try {
            await mutateAsync({ device_ids: [device.id], device_name });
            handleSubmit(device_name);
            handleClose();
        } catch (error) {
            const errorMessage = (error as any).response?.data.message;

            if (!errorMessage) return;
            setError("device_name", {
                type: "api",
                message: errorMessage,
            });
        }
    };

    return (
        <>
            <FormModal
                heading="Update Device Name"
                open={isOpen}
                handleClose={() => handleClose()}
                handleSubmit={handleSubmitForm(onSubmit)}
                icon={<EditIcon />}
            >
                <FormInputField
                    register={register("device_name", {
                        required: "Please enter a device name",
                    })}
                    label="Device Name"
                    error={Boolean(formErrors.device_name?.message)}
                    helperText={formErrors.device_name?.message}
                />
                <ModalFormButtons closeFn={handleClose} text="Submit" isLoading={isLoading} />
            </FormModal>
        </>
    );
};
